import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 0,
        height: 'inherit',
    },
    paper: {
        width: 'auto',
        height: 'inherit',
        boxShadow: 'none',
        background: '#F2F8FF',
        minHeight: '100vh',
        display: 'flex',
        padding: '0', //30px 64px
        flexDirection: 'column',
        alignItems: 'center',
        gap: '64px',
        alignSelf: 'stretch'
    },
    height: {
        height: 'inherit'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    flexContainer: {
        // display: 'contents',
        // flexWrap: 'wrap',
        height: 'inherit',
    },
    flexItem: {
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid #e5e5e5',
        borderRadius: 30,
        boxShadow: ' rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        // margin: '2rem 3rem',
        textAlign: 'center',
        height: '165px',
        maxHeight: '170px',
        padding: '12px',
        background: 'white',
    },
    moduleContent: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
    },
    centerAlignModulePos: {
        display: 'flex',
        alignItems: 'flex-Start',
        gap: '38px',
        justifyContent: 'left',
        margin: '20px 0',
        flexWrap: 'inherit',
        overflowX: 'auto',
        overflowY: 'hidden',
        padding : '0'  //'0 16px'
    },
    searchCenterAligned:{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "center",
        marginTop: '20px'
    },
    leftAlignModulePos: {
        display: 'flex',
        alignItems: 'flex-Start',
        gap: '58px',
        justifyContent: 'left',
        margin: '20px 0',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        overflowY: 'hidden',
        paddingBottom : '20px'
    },
    moduleSection: {
        display: 'flex',
        width: '250px',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: '8px',
        background: '#CCD7DD',

        /* shadow-1/2 */
        boxshadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.12)'
    },
    parentModule: {
        display: 'flex',
        minWidth: '207px ', //
        width: '100%',
        height: '60px',
        padding: '5px 10px',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        // gap: '16px'
        borderRadius: '8px 8px 0 0',


    },
    parentModulePosition: {
        // margin: '16px 50px',
        // padding: '16px 26px',
        height: 'fit-content',
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: '8px',
        marginLeft: '0px',
    },
    childModulePosition: {
        // borderRadius: '8px',
        // background: '#CCD7DD', //var(--background-4, #CCD7DD);
        //    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.12)'
        height: 'fit-content',
        width: '100%',
        minWidth: '207px'
    },
    childModule: {
        display: 'flex',
        padding: '5px 16px', //16px
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '8px',
        alignSelf: 'stretch',
        textAlign: 'left',
        background: '#FFF',
        borderRadius: '0px',
        borderBottom: '1px solid #7285A6',
        boxShadow: '0px -1px 10px 0px rgba(0, 0, 0, 0.12)',
        cursor: 'pointer',
        '&:hover': {
            transform: 'scale(1.1)',
            transformOrigin: 'top',

        }
    },


    appName: {
        // fontSize: '20px',
        justifyContent: 'center',
        color: '#10059F',
        textAlign: 'center',
        leadingTrim: 'both',
        textEdge: 'cap',
        fontFamily: 'Gilroy-Medium',
        fontSize: '36px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '44px', /* 122.222% */
        letterSpacing: '-0.72px',
        paddingTop: '16px'
    },
    width33: {
        width: '33%',
    },
    logoBox: {
        width: '100%',
        height: '60px',
        outline: 'none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // marginTop: '12px'
    },
    parentContent: {
        width: '100%',
        zIndex: 1,
        // marginTop:  '10px'
    },
    contentBox: {
        width: '100%',
        zIndex: 1,
        // margin: '10px 0',
    },
    header: {
        height: '77px'
    },
    image: {
        // marginTop: 12,
        outline: 'none !important'
    },
    ssimage: {
        width: 110,
        height: 110,
        outline: 'none !important'
    },
    parentImg: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%', //75%
        cursor: 'pointer',
    },
    unitBox: {
        backgroundColor: '#f9f9f9'
    },
    Title: {
        color: '#050050',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '32px', /* 145.455% */
        letterSpacing: '-0.44px',
        cursor: 'pointer',
    },
    ParentTitle: {
        color: '#ffffff',
        textAlign: 'center',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        width: '100%'
    },
    subtitle: {
        color: '#050050',
        cursor: 'pointer',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px'/* 142.857% */,
        fontFamily: 'Gilroy-Medium !important',
     },
    unitContent: {
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid #e5e5e5',
        borderRadius: 15,
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        margin: '2rem'
    },
    selectUnit: {
        textAlign: 'right',
        marginTop: '5px',
    },
    select: {
        cursor: 'pointer',
        color: '#050050',
        /* body/xsmall-underline */
        fontFamily: 'Gilroy-Medium',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px', /* 166.667% */
        textDecorationLine: 'underline',
    },
    forwordIcon: {
        outline: 'none',
        marginLeft: '0px',
        padding: '0 12px'
    },
    aboutrgm: {
        padding: '24px 24px 64px',
        // cursor: 'pointer',
        color: '#212121',
        textAlign: 'center',

        /* body/large-regular */
        fontFamily: 'Gilroy-Medium',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '28px' /* 155.556% */
    },
    loadMore: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',
        padding: '10px'
    },
    righReserved: {
        color: '#050050',
        textAlign: 'center',
        // fontFeatureSettings: 'clig' off, 'liga' off;
        fontFamily: 'Gilroy-Medium',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '16px' /* 114.286% */,
        display: 'flex',
        padding: '64px 0 0',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '64px',
        alignSelf: 'stretch'
    },
     
}
));

export { useStyles };