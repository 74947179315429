import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {
  sidebarMenuList,
  getPlayBooksContentPages,
  updatesidebarMenuList,
  getEmbedToken,
  getMsalBasedPlaybookContentPages,
  updatePresistReport,
  updateActiveMenuList,
} from '../../redux/actions/index';
import MenuList from './menulist';
import utils from '../../utils/index'
import { useStyles } from "./indexStyles.js";
export default function PersistentDrawerLeft(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();


  // Redux Response //
  const sideBarMenu = useSelector((state) => state.sideBarMenu);
  const playBooksContent = useSelector((state) => state.playbookContent);
  const playbookContentPages = useSelector((state) => state.playbookContentPages);
  const reportContent = useSelector((state) => state.getReportContent);
  const dynamicReports = useSelector((state) => state.dynamicReports);
  const bookmarkView = useSelector((state) => state.bookmarkView);
  const presistApplicable = useSelector((state) => state.updatePresist);
  const activeSideBarMenu = useSelector((state) => state.activeSideBarMenu);
  

  const { description } = sideBarMenu;

  // lcoal State //

  const [activeIndex, setActiveIndex] = useState(0);
  const [activeSubMenu, setActiveSubMenuName] = useState("");
  const [activeSubMenuID, setActiveSubMenuID] = useState("");
  const [PBIReports, setReportsData] = useState([]);

  const [open, setOpen] = useState(false);
  const [menuList, setMenuList] = useState([]);
  const { activeMsalToken, activeRegion,   activePage } = useSelector((state) => state.activeDynamicData);

  const { state } = history.location;

  // useEffect(() => {
  
  // }, [presistApplicable, activeRegion, state]);

  /**
 * useEffect hook for calling sidebar MenuList API.
 *
 * @example
 * @state
 * const state = application history object state
 * const region = 'APAC'/ 'EMEA'/ 'KCNA'/ 'LAO'
 * const sidebarMenuList = Redux Action dispatcher for sidebarMenuList API Call in case of empty data
 * return (
 *    Sidebar MenuList DATA
 * )
 */
  useEffect(() => {
    if (utils.isValidArray(playBooksContent.data) && activePage) {
      playBooksContent.data.sort((a, b) => { return a.order - b.order });
       let activeIndex = playBooksContent.data.findIndex((el) => el.viewName.trim() ===  activePage.parentModule.trim() );
       if (state && state.type === "bookmark") {
        console.log('bookmarkState', state);
        setActiveIndex(state.viewIndex);
        setActiveSubMenuName(state.activeReport)
      } else if (state && state.type === "ShareReport") {
        console.log('bookmarkState', state);
        setActiveIndex(state.viewIndex);
        setActiveSubMenuName(state.activeReport)
      } else{
        setActiveSubMenuName(activePage.title.trim());
        setActiveIndex(activeIndex);
        setActiveSubMenuID(activePage.background)
      }
      console.log('updatActiveReportPage',111,     playBooksContent.data,   activeIndex, activePage.parentModule, activePage, activePage.title, activePage.background )
    }
    else if (utils.isValidArray(reportContent.data) && activePage) {
      reportContent.data.sort((a, b) => { return a.order - b.order })
      let activeIndex = reportContent.data.findIndex((el) => el.viewName.trim() === activePage.title.trim());
      if (state && state.type === "bookmark") {
        console.log('bookmarkState', state);
        setActiveIndex(state.viewIndex);
        setActiveSubMenuName(state.activeReport)
      } else if (state && state.type === "ShareReport") {
        console.log('bookmarkState', state);
        setActiveIndex(state.viewIndex);
        setActiveSubMenuName(state.activeReport)
      } else{
        setActiveSubMenuName(activePage.title.trim());
        setActiveIndex(activeIndex);
      }
      console.log('updatActiveReportPage',222, activeIndex , activePage.title, reportContent.data)
    }
  }, [activePage, activePage.parentModule, activePage.title, playBooksContent.data, reportContent.data, state, activeRegion])


  /**
 * useEffect hook for calling sidebar MenuList API.
 *
 * @example
 * @state
 * const state = application history object state
 * const region = 'APAC'/ 'EMEA'/ 'KCNA'/ 'LAO'
 * const sidebarMenuList = Redux Action dispatcher for sidebarMenuList API Call in case of empty data
 * return (
 *    Sidebar MenuList DATA
 * )
 */
  useEffect(() => {
    dispatch(updatePresistReport({ presist: true }));
    if (activeRegion && activePage && activePage.sidebar) {
      let params = {
        sideBarType: activePage.sideBarKey,
        region: activeRegion
      }
      setOpen(activePage.sidebar)
      dispatch(sidebarMenuList(params));
    }

  }, [dispatch, activeRegion, activePage]);

  const getActiveMenu = (value) => {
    setActiveIndex(value.activeIndex);
    if (!!state) {
      state.type = "presistReport";
    }
  };

  /**
 * useCallback hook for updating self service, foundation analysis and resources data to the user.
 *
 * @example
 * @state
 * const activePage =  self service, foundation analysis and resources
 * const data = description
 * const region = 'APAC'/ 'EMEA'/ 'KCNA'/ 'LAO'
 * const viewName = description
 * const viewIndex = 0
 * return (
 *    self service, foundation analysis and resources side bar data
 * )
 */
  const updateFASidebarData = useCallback(() => {
    if (activePage && !activePage.embedReport && Array.isArray(description) && description.length > 0) {
      setMenuList(description);
      setReportsData([]);
      dispatch(updatesidebarMenuList([]));
    }
    else if (activePage && activePage.embedReport && Array.isArray(description) && description.length > 0) {
      description.sort(function (a, b) { return a.order - b.order });
      let priceElasticity = description.filter((el) => { el['customizableFlag'] = true; return !el.embedReport });
      let embedReport = description[activeIndex] ? description[activeIndex].embedReport : description[0].embedReport;
      if (!!embedReport) {
        if (!!reportContent && reportContent.data && Array.isArray(reportContent.data) && reportContent.data.length > 0) {
          reportContent.data.forEach((item) => item['embedReport'] = true);
          let report = reportContent.data[activeIndex];
          if (report) {
            report['embedReport'] = true;
            if (state && state.type === "bookmark") {
              setActiveIndex(state.viewIndex);
            } else if (state && state.type === "ShareReport") {
              setActiveIndex(state.viewIndex);
            } 
          }
          let FAData = reportContent.data.concat(priceElasticity);
          setReportsData(FAData);
        }
      }
    }
    else if (Array.isArray(description) && description.length === 0) {
      setMenuList(description);
      setReportsData([]);
      dispatch(updatesidebarMenuList([]));
    }
  }, [description, dispatch, reportContent, activePage, activeIndex, state]);

  /**
 * useCallback hook for updating self service, foundation analysis and resources data to the user.
 *
 * @example
 * @state
 * const activePage =  self service, foundation analysis and resources
 * const data = description
 * const region = 'APAC'/ 'EMEA'/ 'KCNA'/ 'LAO'
 * const viewName = description
 * const viewIndex = 0
 * return (
 *    self service, foundation analysis and resources side bar data
 * )
 */
  const updatebusinessUnitSidebar = useCallback(() => {
    if (Array.isArray(description) && description.length > 0) {
      description[0].isReportActive = true;
      setMenuList(description);
      setReportsData([]);
    }
    dispatch(updatesidebarMenuList([]));
  }, [description, dispatch,]);

  /**
  * useEffect hook for updating business unit data to the user.
  *
  * @example
  * @state
  * const activePage = business unit activePage
  * const data = description
  * const region = 'APAC'/ 'EMEA'/ 'KCNA'/ 'LAO'
  * const viewName = description
  * const viewIndex = 0
  * return (
  *    business unit activePage
  * )
  */
  useEffect(() => {
    if (activePage) {
      const { embedReport, multipleMenuList, sidebar, viewBuilderReport, key } = activePage;
      console.log('SideBarContent-presistApplicable', activePage, multipleMenuList, sidebar, viewBuilderReport, key,);
      if (!viewBuilderReport && !sidebar) {
        if (utils.isValidArray(playBooksContent.data)) {
          playBooksContent.data.sort((a, b) => { return a.order - b.order })
          setReportsData(playBooksContent.data);
          console.log('SideBarContent-playbooks', 111, activePage, playBooksContent.data);
        }
        else if (utils.isValidArray(reportContent.data)) {
          reportContent.data.sort((a, b) => { return a.order - b.order })
          setReportsData(reportContent.data);
          console.log('SideBarFAContent reportContent', 444, 111, reportContent.data);
        }
        else if (utils.isValidArray(dynamicReports.data)) {
          // dynamicReports.data.sort((a, b) => {return a.order - b.order})
          setReportsData(dynamicReports.data);
          console.log('SideBarFAContent dynamicReports', 555, 111, dynamicReports.data);
        }
        else {
          setMenuList([{ viewName: activePage.pageName }]);
          setReportsData([]);
        }
      }
      else if (viewBuilderReport && !sidebar) {
        if (utils.isValidArray(reportContent.data)) {
          reportContent.data.sort((a, b) => { return a.order - b.order })
          setReportsData(reportContent.data);
        }
        else {
          setMenuList([{ viewName: activePage.pageName }]);
          setReportsData([]);
        }
      }
      else if (!embedReport && !multipleMenuList && sidebar) {
        // RS & FA & SS
        console.log('UpdatedSidebarData-updatebusinessUnitSidebar', 555, 'RS & FA & SS', activePage)
        updatebusinessUnitSidebar();
      }
    }
  }, [dispatch, activeRegion, activePage, bookmarkView.bookmarkState, bookmarkView.bookmarkID, playBooksContent.data, reportContent.data, updateFASidebarData, updatebusinessUnitSidebar, dynamicReports.data]);


  /**
  * funcation callback from child component.
  *
  * @example
  * @state
  * const value = funcation arguments
  * )
  */
  const getActiveSubMenu = (value) => {
    console.log('UpdatedSidebarData getActiveSubMenu', value);
    setActiveSubMenuName(value.activeSubMenuName);
  };

  /**
  * useEffect hook for updating Playbooks/ Dartboards Reports Token and pages.
  *
  * @example
  * @state
  * const PBIReports = Playbooks/ Dartboards/ Customized Report
  * const activeIndex = active menu index in nested array element of reports
  * const grouped =  grouped basis report based on region
  * const customizableFlag = customized report based on pages
  * const msalTokenEnabled = MSAL Token Enabled Report based on region
  * return (
  *    useEffect hook for updating Playbooks / Dartboards Reports Token and pages.
  * )
  */
  useEffect(() => {
    if (!!presistApplicable.presist && utils.isValidArray(PBIReports)) {
      let activeReport = PBIReports[activeIndex] ? PBIReports[activeIndex] : PBIReports[0];
      console.log('APICALL-reports', PBIReports, activeReport)
      if (activeReport && activeReport.region === activeRegion) {
        let { msalEnabled, grouped, reportPages, hiddenPages, htmlTemp, subReport, customizableFlag } = activeReport;
        if (!msalEnabled && !grouped) {
          console.log('APICALL-reportsToken', PBIReports, activeReport, activeRegion, activePage)
          let params = utils.checkValidPBIds(htmlTemp, customizableFlag);
          dispatch(getEmbedToken(params));
        }
        else if (!!grouped) {
          let reportId = utils.checkValidPBIds(htmlTemp, customizableFlag);
          console.log('APICALL-kcnasubReports', PBIReports, activeReport)
          const params = {
            ...reportId,
            viewID: activeReport['viewId'],
            pageType: activePage.key
          };
          dispatch(getMsalBasedPlaybookContentPages(params));
          if (!msalEnabled) {
            console.log('APICALL-reportsToken', PBIReports, activeReport, activeRegion, activePage)
            let params = utils.checkValidPBIds(htmlTemp, customizableFlag);
            dispatch(getEmbedToken(params));
          }
        }
        if (!grouped && reportPages && !hiddenPages && subReport.length === 0) {
          console.log('APICALL-reportspages', PBIReports, activeReport)
          let params = utils.checkValidPBIds(htmlTemp, customizableFlag);
          dispatch(getPlayBooksContentPages(params));
        }
      }
    }
  }, [dispatch, PBIReports, activePage, activeIndex, presistApplicable, activeRegion]);


  /**
  * useEffect hook for updating Playbooks/ Dartboards Reports pages and token w.r.t. active menu.
  *
  * @example
  * @state
  * const PBIReports = Playbooks/ Dartboards/ Customized Report
  * const activeIndex = Active menu index in nested array element of PBIReports
  * const playbookContentPages = Active Menu's subReport/ Report Section/ Report Pages
  * const customizableFlag = customized report based on pages
  * const msalTokenEnabled = MSAL Token Enabled Report based on region
  * const htmlTemp = Object of groupId, reportId, MSAL TOKEN
  * const subReport = Active Menu's subReport Array where Report Pages will be pushed.
  * const openMenu =  Active Menu's Open/ Close Status
  * const activeSubMenuName/ID = Active Menu's subReport Array where default 0th index value will be active.
  * return (
  *    useEffect hook for updating Playbooks / Dartboards Reports Token and pages.
  * )
  */

  useEffect(() => {
    if (utils.isValidArray(PBIReports)) {
      dispatch(updatesidebarMenuList([]));
      let activeReport = PBIReports[activeIndex] ? PBIReports[activeIndex] : PBIReports[0];
      if (activeReport) {
        let { viewName, customizableFlag, htmlTemp, msalEnabled, grouped, reportPages } = activeReport;
        const { data } = playbookContentPages;
        if (reportPages || grouped) { activeReport.openMenu = true; }
        activeReport.isReportActive = true;
        if (data && Array.isArray(data) && data.length > 0 && htmlTemp && htmlTemp['reportId'] === playbookContentPages['reportId']) {
          data.sort(function (a, b) { return a.order - b.order });
          let landingPageIndex = data.findIndex((el)=> el.displayName.startsWith('Landing'));
            if(landingPageIndex > -1){
              data.splice(landingPageIndex,1);
            }
           console.log('updateActiveReportPagesInLevers', 111, activeReport, landingPageIndex, data, activeSubMenu, activeSideBarMenu)
          activeReport['subReport'] = data;
        }
        if (!grouped && !msalEnabled) {
          dispatch(updateActiveMenuList(activeReport));
          console.log('updateActiveReportPagesInLevers', 222, activeReport, activeSubMenu, activeSideBarMenu)
        }
        else if (grouped) {
          if (Object.keys(activeSideBarMenu).length > 0 && activeSideBarMenu.viewName === viewName) {
            let updateReport = { ...activeReport }
            if (msalEnabled) {
              updateReport['htmlTemp'] = { ...activeSideBarMenu.htmlTemp, msalToken: activeMsalToken }
            }
            else if (!msalEnabled) {
              updateReport['htmlTemp'] = { ...activeSideBarMenu.htmlTemp }
            }
            dispatch(updateActiveMenuList(updateReport));
            activeReport = updateReport;
          }
          else if (Object.keys(activeSideBarMenu).length === 0 && activeSubMenu) {
            let updateReport = { ...activeReport }
            if (activeReport['subReport'].length > 0) {
              let activeSubReport = activeReport['subReport'].findIndex(el => el.displayName === activeSubMenu);
              let index = activeSubReport > -1 ? activeSubReport : 0;
              let htmlTemp = activeReport['subReport'][index].htmlTemp;
              console.log('updateActiveReportPagesInLevers', 333, activeReport, activeSubReport, index, activeSubMenu, activeReport['subReport'][index])
              if (msalEnabled) {
                updateReport['htmlTemp'] = { ...htmlTemp, msalToken: activeMsalToken }
              }
              else if (!msalEnabled) {
                updateReport['htmlTemp'] = { ...htmlTemp }
              }
            }
            activeReport = updateReport;
            dispatch(updateActiveMenuList(updateReport));
          }
          else if (msalEnabled) {
            activeReport['htmlTemp']['msalToken'] = activeMsalToken;
            dispatch(updateActiveMenuList(activeReport));
          }
          else {
            dispatch(updateActiveMenuList(activeReport));
          }
        }
        else if (msalEnabled && !grouped) {
          if (!customizableFlag) {
            activeReport['htmlTemp']['msalToken'] = activeMsalToken;
            dispatch(updateActiveMenuList(activeReport));
          }
          else if (customizableFlag) {
            data.sort(function (a, b) { return a.order - b.order });
            const playBookPages = data;
            activeReport['subReport'] = playBookPages;
            dispatch(updateActiveMenuList(activeReport));
          }
        }
        PBIReports.forEach((item) => {
          if (viewName === item.viewName) {
            if (reportPages || grouped) {
              item.openMenu = true;
            }
            item.isReportActive = true;
            item.htmlTemp = activeReport.htmlTemp;
          }
          else if (viewName !== item.viewName) {
            if (reportPages || grouped) {
              item.openMenu = false;
            }
            item.isReportActive = false;
          }
        });
        dispatch(updatesidebarMenuList(PBIReports));
      }
    }
    else {
      dispatch(updateActiveMenuList({}));
      dispatch(updatesidebarMenuList([]));
    }
  }, [activeIndex, activeSubMenu, activeRegion, playbookContentPages, dispatch, PBIReports, bookmarkView.bookmarkState, bookmarkView.bookmarkID, activeMsalToken, activeSideBarMenu]);
  // activeSubMenuIndex,


  /**
  * Function for Opening/Closing the left side bar menu
  *
  * @example
  *
  */
  const handleDrawerToggle = () => {
    setOpen(!open); //  
  };

  const handleDrawerToggleOff = () => {
    setOpen(false); //
  };


  console.log('UpdatedSidebarData-ActiveMenuData',
    activePage, activeSubMenu, activeIndex, activeSubMenuID,
    // 'SubMenu*********',
    // '>>>', activeRegion, activeMenu,  menuList  '^^^',
    //  activeSubMenuIndex, state
    // '????', PBIReports,  
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <ClickAwayListener onClickAway={handleDrawerToggleOff}>
        <Drawer
          drawerHidden
          className={open ? classes.drawer : classes.drawerHidden}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{ paper: classes.drawerPaper }}
          onMouseLeave={handleDrawerToggleOff}
        >
          <MenuList
            menuList={menuList}
            subRegion={props.subRegion}
            getActiveMenu={getActiveMenu}
            getActiveSubMenu={getActiveSubMenu}
            activeSubMenu={activeSubMenu}
            activeSubMenuID={activeSubMenuID}
          />
        </Drawer>
      </ClickAwayListener>
      <div className={open ? classes.toggleLeft : classes.toggleRight}>
        <IconButton
          edge="end"
          style={{ outline: "none" }}
          onMouseOver={handleDrawerToggle}
        >
          {open ? <ChevronLeftIcon  /> : <ChevronRightIcon/>}
        </IconButton>
      </div>
      <main className={clsx(classes.content, { [classes.contentShift]: open })}>
        <props.component
          subRegion={props.subRegion}
          activeIndex={activeIndex}
          open={open}
        />
      </main>
    </div>
  );
}


