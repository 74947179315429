import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {
    dynamicReportContent,
    updateCustomizedViewBuilderJSON
} from '../../redux/actions/index';
import DefaultReport from '../reports/defaultReport';
import { useStyles } from "../additionalReports/style.js";
import cloneDeep from 'lodash/cloneDeep';
import NoData from '../../assets/icons/NoData.png';

import CircularProgress from '@material-ui/core/CircularProgress';

const DynamicReports = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const { activeIndex, } = props;

    const dynamicReports = useSelector((state) => state.dynamicReports);
    const { activeMsalToken, activeRegion, activePage, } = useSelector((state) => state.activeDynamicData);

    const errorMessage = useSelector((state) => state.getErrorMessages);
    const [noReports, setNoReport] = React.useState(null);
    const activeReport = useSelector((state) => state.activePBIReport);
    const [navContentPaneEnabled, SetNavContentPaneEnabled] = useState(true);


    useEffect(() => {
        const noReport = errorMessage?.data?.find(item => item.errorType === "No Reports Available")
        setNoReport(noReport?.errorMessage)
    }, [errorMessage]);

    useEffect(() => {
        let selectedRegion;
        if (activeRegion && activePage) {
            if (activePage.description.startsWith('Global')) {
                selectedRegion = "Global"
            }
            else {
                selectedRegion = activeRegion;
            }
            if (activePage.key !== "Playbooks") {
                const params = {
                    pageType: activePage.key,
                    region: selectedRegion

                }
                dispatch(dynamicReportContent(params));
            }
        }
    }, [dispatch, activeRegion, activePage]);

    useEffect(() => {
        if (Object.keys(activeReport).length > 0) {
            if (activeReport) {
                let { msalEnabled, customizableFlag, grouped, reportPages } = activeReport;
                let tempViewBuilderObj = cloneDeep(activeReport);
                if (msalEnabled && customizableFlag) {
                    let { htmlTemp } = tempViewBuilderObj;
                    let element = !!htmlTemp && Object.keys(htmlTemp).length > 0 && Object.keys(htmlTemp).filter(key => htmlTemp[key].type === "dashboard");
                    if (!!htmlTemp && htmlTemp !== "NULL") {
                        htmlTemp[element]['msalToken'] = activeMsalToken ? activeMsalToken : sessionStorage.getItem("msalAccessToken");
                    }
                }
                dispatch(updateCustomizedViewBuilderJSON(tempViewBuilderObj));
                if (grouped) {
                    SetNavContentPaneEnabled(true)
                }
                else if (reportPages) {
                    SetNavContentPaneEnabled(false)
                }
            }
        }
        else {
            dispatch(updateCustomizedViewBuilderJSON({}));
        }
    }, [dispatch, activeMsalToken, activeReport,]);

    const reportEmbedding = () => {
        let { loading, responseDefault, error } = dynamicReports;
        if (loading && responseDefault) {
            return (
                <div className={classes.position}>
                    <CircularProgress color="primary" />
                </div>
            )
        }
        else if (!loading && !responseDefault && !!error) {
            return (
                <div className={classes.serverDown}>
                    <span><img src={NoData} alt="serverDown" className={classes.imgFit} /></span>
                    <div className={classes.errorMessage}>{noReports}</div>
                </div>
            )
        }
        if (Object.keys(activeReport).length > 0) {
            return (
                <DefaultReport
                    activeIndex={activeIndex}
                    navContentPaneEnabled={navContentPaneEnabled ? navContentPaneEnabled:  activeReport.viewHeight === 1 ? true : false}
                />
            )
        }
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Grid container item xs={12} className={classes.flexContainer}>
                    {reportEmbedding()}
                </Grid>
            </Paper>
        </div>
    );
};


export default DynamicReports;