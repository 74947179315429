import React, { useState, useEffect , /*useContext */ } from 'react';
import { useDispatch, useSelector } from "react-redux";
// import { LayoutContext } from "../layoutContent/index";
import { getAllPagesContent } from '../../redux/actions/index';
import SelfService from './selfServices';

const TabConfiguration = ( ) => {
  const dispatch = useDispatch();

  const getPageContent = useSelector((state) => state.getPageContent);
  // const values = useContext(LayoutContext);

  const [selectedTab, setSelectedTab] = useState("PBI Pro User");
  const [selectedTabName, setSelectedTabName] = useState("");

  const [tabledata, setData] = useState([]);

  const { data, loading  } = getPageContent;

  const { activeRegion , activePage } = useSelector((state) => state.activeDynamicData);

  useEffect(() => {
    let keys = !!data && data.description && Object.keys(data.description);
    if(Array.isArray(keys) && keys.length> 0){
      setSelectedTab(keys[0]);
      setSelectedTabName(data.description[keys[0]]['display_name']);
    }else{
      setSelectedTab("PBI Pro User");
    }
  }, [activeRegion, data]);

  useEffect(() => {
    if (activeRegion && activePage   && activePage.key !== "Playbooks") {
        const params = {
            pageType: activePage.key,
            region: activeRegion,
        }
        dispatch(getAllPagesContent(params));
    }
}, [dispatch, activePage, activeRegion]);

  const changeUserSelectedTab = (tab) => {
    setSelectedTab(tab);
    setSelectedTabName(data.description[tab]['display_name']);
  };

  useEffect(() => {
    const userData = data && data.description && data.description[`${selectedTab}`] && data.description[`${selectedTab}`].table;
    setData(userData);
  }, [data, getPageContent, selectedTab]);

  return (
    <div className="container-fluid bg-gray full-screen-height">
      <div className="row bg-white dashboard-button-row">
        <div className="d-flex dashboard-button-row">
          {
            !!data && data.description && Object.keys(data.description).length > 0 &&
            Object.keys(data.description).map((item)=>
            (
               <button className={`btn btn-plain ${selectedTab === item ? "active" : null}`} onClick={(e) => { changeUserSelectedTab(item) }}>{data.description[item]['display_name']}</button>
            )
            )
          }
        </div>
      </div>
      <div className="row bg-white">
        <SelfService data={tabledata} loading={loading} selectedTab={selectedTabName}/>
      </div>
    </div>
  );

}

export default TabConfiguration;
