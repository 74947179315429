import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import cloneDeep from 'lodash/cloneDeep';
import utils from '../../utils/index';
import { addNewChildModule, updateChildModule, toastTriggered, getRegions } from '../../redux/actions';
import BarLoader from "react-spinners/BarLoader";
import toastProperties from "../../assets/data/toastProperty";


const ChildModule = (props) => {

    const dispatch = useDispatch();

    const { activeBusinessUnit, activeRegion, activeRegionSpecificConfigs, activeRoutes } = useSelector((state) => state.activeDynamicData);
    const RegionMappingData = useSelector((state) => state.getRegionMappingData);
    const parentMappedIds = useSelector((state) => state.parentBuMapped);
    const childParentMapping = useSelector((state) => state.childParentMapping);
    // const apiFailure = useSelector((state) => state.apiFailure)

    const [moduleName, setModuleName] = useState('');
    const [description, setDescription] = useState('');
    const [path, setPath] = useState('');
    const [adGroup, setAdGroup] = useState('');
    const [platformLink, setPlatformLink] = useState('');
    const [accessRestriction, setAccess] = useState(false);
    const [reportEmbed, setReportEmbed] = useState(false);
    const [reportLink, setReportLink] = useState(false);
    const [Link, setLink] = useState(false);
    const [subReport, setSubReport] = useState(true);
    const [customizedReport, setCustomizedReport] = useState(false);
    const [Share, setShare] = useState(false);
    const [Export, setExport] = useState(false);
    const [customMessage, setCustomMessage] = useState({});
    const [createModule, setCreateModule] = useState(false);
    const [requireCustomized, setRequireCustomized] = useState(true);
    const [contentType, setContentType] = useState('subReport');
    const [globalEnabledModule, setGlobalEnabledModule] = useState(false);
    const [globalRegion, setGlobalRegion ] = useState(false);
     

    const { moduleData, closeDailog } = props;

    useEffect(() => {
        const { parentBuMapping } = parentMappedIds;
        const { childParentIds } = childParentMapping;
        if (Array.isArray(parentBuMapping) && parentBuMapping.length > 0) {
            parentBuMapping.forEach((el) => {
                el.mappingResricted = false;
                if (Array.isArray(childParentIds) && childParentIds.length > 0) {
                    let childModule = childParentIds.find((child) => child.parentId === el.parentId);
                    el.childId = childModule && childModule.childId;
                }
            });
        }
    }, [childParentMapping, parentMappedIds]);


    useEffect(() => {
        const { childModuleData } = moduleData;
        if (childModuleData && Object.keys(childModuleData).length > 0) {
            setModuleName(childModuleData.title);
            setDescription(childModuleData.description);
            setAccess(childModuleData.accessRestriction);
            setReportEmbed(childModuleData.embedReport);
            setReportLink(!childModuleData.embedReport);
            setLink(childModuleData.embedLinkType)
            setPlatformLink(childModuleData.path);
            setShare(childModuleData.shareFeature);
            setExport(childModuleData.exportFeature);
            setPath(childModuleData.path);
        }
    }, [moduleData]);

    useEffect(() => {
        const { parentModuleData } = moduleData;
        setContentType(parentModuleData.contentType)
        if (parentModuleData.parentModule === 'Control Tower') {
            setGlobalEnabledModule(true);
            setGlobalRegion(true);
        }
        if (parentModuleData.contentKey === 'Playbooks' && subReport) {
            setPath('/analytics/playbooks-reports');
            setRequireCustomized(true);
        }
        else if (parentModuleData.contentKey === 'Playbooks' && !subReport) {
            setPath('/analytics/playbooks-reports');
            setRequireCustomized(true)
        }
        else if (parentModuleData.contentKey === 'Ad-hoc Reports') {
            setPath('/analytics/ad-hoc-reports');
            setRequireCustomized(false)
        }
        else if (parentModuleData.contentKey === 'Control Tower') {
            setPath('/analytics/control-tower');
            setRequireCustomized(false)
        }
        else if (parentModuleData.contentKey === 'Additional Reports') {
            setPath('/analytics/customized-reports');
            setRequireCustomized(false)
        }
        else if (reportEmbed) {
            setPath('/dynamicReports/dynamic-pbi-reports')
        }
        else if (!reportEmbed) {
            setPath('/dynamicReports/dynamic-report-links')
        }
    }, [activeRoutes.key1, customizedReport, moduleData, reportEmbed, subReport]);

    const UpdateFormInput = (e) => {
        var name = e.target.name;
        var value = e.target.value;
        if (name === 'moduleName') {
            setModuleName(value)
        }
        else if (name === 'description') {
            setDescription(value)
        }
        else if (name === 'adGroup') {
            setAdGroup(value);
        }
        else if (name === 'platformLink') {
            setPlatformLink(value);
            setPath(value)
        }
    }

    const   onhandleCheckbox = (e) => {
        var name = e.target.name;
        var value = e.target.checked;
        if (name === 'accessRestriction') {
            setAccess(value);
        }
        else if (name === 'reportEmbed') {
            setReportEmbed(true);
            setReportLink(false);
            setLink(false);
            setPath('/dynamicReports/dynamic-pbi-reports')
        }
        else if (name === 'reportLink') {
            setReportLink(true);
            setReportEmbed(false);
            setLink(false);
            setPath('/dynamicReports/dynamic-report-links')
        }
        else if (name === 'Link') {
            setLink(true);
            setReportLink(false);
            setReportEmbed(false);
            setSubReport(false);
        }
        else if (name === 'Share') {
            setShare(value)
        }
        else if (name === 'Export') {
            setExport(value)
        }
        else if (name === 'createModule') {
            setCreateModule(value);
            setSubReport(false);
            setReportEmbed(true)
        }
        else if (name === 'subReport') {
            setSubReport(value);
            setCreateModule(false);
            setCustomizedReport(false)
            setPath('/analytics/playbooks-reports')
        }
        else if (name === 'customizedReport') {
            setCustomizedReport(value)
            setSubReport(false);
            setCreateModule(false);
            setPath('/analytics/playbooks-reports')
        }
        else if(name === 'globalRegion'){
            setGlobalRegion(value);
         }
    };

    const trackEvents = (title, type) => {
        utils.userTrackingInfo({
            region: activeRegion,
            actionType: 'Landing Page Admin Activity',
            pageName: 'Admin Dashboard',
            reportName: title,
            pageDetails: type,
        });
    }

    const saveChildModuleDetails = () => {
        const { childModuleData, order, parentModuleData } = moduleData;
        let businessUnit = activeBusinessUnit;
        const childIds = [];
        const { data } = RegionMappingData;
        const { parentBuMapping } = parentMappedIds;
        const { childParentIds } = childParentMapping;
        let childModuleDetails = {};
        let customObj = {};
        let uniqueParentIds;
        let usergroup = data.filter((element) => element.userGroup.includes(process.env.REACT_APP_OKTA_ENV));
        let uniqueIds = utils.uniqueAdGroup(usergroup, 'userGroup')
        if (Array.isArray(parentBuMapping) && parentBuMapping.length > 0) {
            parentBuMapping.forEach((el, index) => {
                let childModule = childParentIds.find((child) => child.parentId === el.parentId);
                el.mappingResricted = accessRestriction
                el.childId = childModule && childModule.childId;
            });
            uniqueParentIds = parentBuMapping.filter(o => uniqueIds.some(({ regionBuId }) =>
                regionBuId === o.regionBuId
            ))
        }
        if (Array.isArray(childParentIds) && childParentIds.length > 0) {
            childParentIds.forEach((el) => {
                childIds.push(el.childId);
            })
        }
        console.log('uniqueParentIds', data, parentModuleData, moduleName, parentModuleData.parentModule, usergroup, uniqueIds, parentBuMapping, uniqueParentIds);
        if (moduleName === '' && description === '') {
            customObj.error = true;
            customObj.customMessage = `All Input value Can't be Empty, Please Enter the Value`;
            setCustomMessage(customObj);
        }
        else if (moduleName === '') {
            customObj.error = true;
            customObj.customMessage = `Title value Can't be Empty, Please Enter the Value`;
            setCustomMessage(customObj);
        }
        else if (description === '') {
            customObj.error = true;
            customObj.customMessage = `Description value Can't be Empty, Please Enter the Value`;
            setCustomMessage(customObj);
        }
        else if (Link && platformLink === '') {
            customObj.error = true;
            customObj.customMessage = `Link to KC Platform value Can't be Empty, Please Enter the Value`;
            setCustomMessage(customObj);
        }
        else {
            customObj.loading = true;
            setCustomMessage(customObj);
            if (subReport && !customizedReport && parentModuleData.contentKey === "Playbooks") {
                console.log('parentModuleDataKey', 111, parentModuleData['key'])
                childModuleDetails['parentModule'] = parentModuleData.parentModule
                childModuleDetails['globalFilter'] = true;
                childModuleDetails['key'] = "Playbooks";
                childModuleDetails['pageName'] = "Playbooks";
            }
            else if (!subReport && customizedReport && parentModuleData.contentKey === "Playbooks") {
                console.log('parentModuleDataKey', 222, parentModuleData['key'])
                childModuleDetails['parentModule'] = moduleName
                childModuleDetails['globalFilter'] = false;
                childModuleDetails['key'] = "Playbooks";
                childModuleDetails['pageName'] = "Playbooks";
            }
            else if (subReport && !customizedReport && parentModuleData.contentKey !== "Playbooks") {
                console.log('parentModuleDataKey', 333, parentModuleData['key'])
                childModuleDetails['parentModule'] = moduleName
                childModuleDetails['globalFilter'] = false;
                childModuleDetails['key'] = parentModuleData['key'];
                childModuleDetails['pageName'] = parentModuleData.parentModule;
            }
            else if (Link && !subReport && !customizedReport) {
                console.log('parentModuleDataKey', 444, parentModuleData['key'])
                childModuleDetails['parentModule'] = moduleName
                childModuleDetails['globalFilter'] = false;
                childModuleDetails['key'] = parentModuleData['key'];
                childModuleDetails['pageName'] = parentModuleData.parentModule;
            }
            else if (!subReport || !customizedReport) {
                console.log('parentModuleDataKey', 555, parentModuleData['key'])
                childModuleDetails['parentModule'] = parentModuleData.parentModule
                childModuleDetails['globalFilter'] = false;
                childModuleDetails['key'] = parentModuleData['key'];
                childModuleDetails['pageName'] = parentModuleData.parentModule;
            }
            childModuleDetails['region'] = activeRegion;
            childModuleDetails['title'] = moduleName;
            childModuleDetails['description'] = globalRegion ?  'Global - ' + description  :  description;
            childModuleDetails['sideBarKey'] =   moduleName;
            childModuleDetails['background'] = '#1306C1';
            childModuleDetails['accessRestriction'] = accessRestriction;
            childModuleDetails['adGroupName'] = adGroup;
            childModuleDetails['pbiLandingPageNavigation'] = true;
            childModuleDetails['multipleMenuList'] = subReport || reportEmbed || customizedReport ? true : false;
            childModuleDetails['embedReport'] = reportEmbed;
            childModuleDetails['viewBuilderReport'] = false;
            childModuleDetails['sidebar'] = false;
            childModuleDetails['createBookmark'] = subReport || reportEmbed || customizedReport ? true : false;
            childModuleDetails['embedLinkType'] = Link;
            childModuleDetails['exportFeature'] = Export;
            childModuleDetails['shareFeature'] = Share;
            childModuleDetails['childOrder'] = order ? order : businessUnit.length + 1;
            childModuleDetails['routes'] = activeRoutes;
            childModuleDetails['path'] = path;
            childModuleDetails['msalTokenEnabled'] = activeRegionSpecificConfigs.msalTokenEnabled;
            childModuleDetails['subReport'] = reportEmbed ? false : true;
            childModuleDetails['viewStatus'] = 'Active';
            let params = {
                childModuleDetails
            }
            console.log('addNewSubReportUnderModule', childModuleDetails, parentModuleData);
            if (childModuleData) {
                childModuleDetails['parentId'] = uniqueParentIds;
                childModuleDetails['childId'] = childIds;
                childModuleDetails['childModule'] = childModuleData.title;
                dispatch(updateChildModule(params))
                    .then((res) => {
                        toastProperties.success["description"] = res.data.message;
                        dispatch(toastTriggered([toastProperties.success]));
                        dispatch(getRegions());
                        closeDailog(true);
                        customObj.loading = false;
                        setCustomMessage(customObj);
                        trackEvents(moduleName, `${moduleName} module created for ${activeRegion}`)
                    })
                    .catch(() => {
                        toastProperties.error["description"] = `There is some problem while updating ${moduleName} Child module in region ${activeRegion}. Please try again in some time.`;
                        dispatch(toastTriggered([toastProperties.error]));
                        closeDailog(true);
                        customObj.loading = false;
                        setCustomMessage(customObj);
                    });
            }
            else {
                childModuleDetails['parentId'] = uniqueParentIds;
                childModuleDetails['regionBuId'] = parentModuleData.regionBuId;
                dispatch(addNewChildModule(params))
                    .then((res) => {
                        toastProperties.success["description"] = res.data.message;
                        dispatch(toastTriggered([toastProperties.success]));
                        dispatch(getRegions());
                        closeDailog(true);
                        customObj.loading = false;
                        setCustomMessage(customObj);
                        trackEvents(moduleName, `${moduleName} module created for ${activeRegion}`)
                    })
                    .catch(() => {
                        toastProperties.error["description"] = 'There is some error creating a new child module, Please try again in sometime.';
                        dispatch(toastTriggered([toastProperties.error]));
                        closeDailog(true);
                        customObj.loading = false;
                        setCustomMessage(customObj);
                    });
            }
        }
    };

    // const renderAdGroup = () => {
    //     if (accessRestriction) {
    //         return (
    //             <div className="col-12  col-xl-12 form-group tile-new">
    //                 <label className="tile-new">
    //                     Ad Group Name
    //                 </label>
    //                {
    //                     regionSpecificGroup.filter((element) => element.userGroup.includes(activeRegion)).map((element, index) => (
    //                         <div className="col-12  col-xl-12 form-group tile-new" key={index}>
    //                             <input className='px-radio' type={'checkbox'} name={'adGroup'+ index}  checked={element.checked} onChange={(e) => handleAdGroup(element)} />
    //                             {element.userGroup}
    //                         </div>
    //                     ))
    //                 } */}
    //                 <input value={adGroup} name="adGroup" type="text" className="form-control tile-new" placeholder="Enter the Ad Group Name" onChange={(e) => UpdateFormInput(e)} />
    //             </div>
    //         )
    //     }
    // };

    const renderPlatformLink = () => {
        if (Link) {
            return (
                <div className="col-12  col-xl-12 form-group tile-new">
                    <label className="tile-new">
                        Link to KC Platform
                    </label>
                    <input value={platformLink} name="platformLink" type="text" className="form-control tile-new" placeholder="Enter KC platform's Link (like Tip$)" onChange={(e) => UpdateFormInput(e)} />
                </div>
            )
        }
    };

    const renderConditionalFeature = () => {
        if (reportEmbed && createModule) {
            return (
                <div className="col-12  col-xl-12 form-group tile-new">
                    <label className="">Module Features</label>
                    <br></br>
                    <input className='px-radio' type={'checkbox'} name="Share" value={Share} checked={Share} onChange={(e) => onhandleCheckbox(e)} />  Share
                    <br></br>
                    <input className='px-radio' type={'checkbox'} name="Export" value={Export} checked={Export} onChange={(e) => onhandleCheckbox(e)} />  Export
                </div>
            )
        }

    };

    const renderEventMessage = () => {
        if (customMessage.loading) {
            return (
                <div className="waiting_Loader">
                    <div className="prettier">Please wait...</div>
                    <BarLoader
                        size={150}
                        color={"#123abc"}
                        height={4}
                        width={100}
                        loading={true}
                    />
                </div>
            )
        }
        else if (customMessage.error) {
            return (
                <div className="col-12  col-xl-12 flex mt-0" style={{ justifyContent: "flex-start" }}>
                    <div className="col-12  col-xl-12 error"> Error : {customMessage.customMessage}</div>
                </div>
            )
        }
    }

    const reportEmbeddingType = () => {
        if (createModule) {
            return (
                <>
                    <br></br>
                    <input className='px-radio' type={'checkbox'} name="reportEmbed" checked={reportEmbed} onChange={(e) => onhandleCheckbox(e)} />  Report Embed
                    <br></br>
                    <input className='px-radio' type={'checkbox'} name="reportLink" checked={reportLink} onChange={(e) => onhandleCheckbox(e)} />  Report Link
                 </>
            )
        }
        else if (!createModule) {
            return (
                <>
                    <br></br>
                    <input className='px-radio' type={'checkbox'} name="subReport" checked={subReport} onChange={(e) => onhandleCheckbox(e)} /> Sub Report Under Module
                    <br></br>
                    {
                        requireCustomized ?
                            <>
                                <input className='px-radio' type={'checkbox'} name="customizedReport" checked={customizedReport} onChange={(e) => onhandleCheckbox(e)} /> Customized Report
                            </>
                            : null
                    }
                </>
            )
        }
    }

    const createNewChildModule = () => {
        if (contentType === 'module') {
            return (
                <div className="col-12  col-xl-12 form-group tile-new">
                    <label className="">Create Module</label>
                    <br></br>
                    <input className='px-radio' type={'checkbox'} name="createModule" checked={createModule} onChange={(e) => onhandleCheckbox(e)} /> Create New Child Module
                </div>
            )
        }
    }

    const moduleAccessRestriction = () => {
        if (createModule) {
            return (
                <div className="col-12  col-xl-12 form-group tile-new">
                    <label className="">
                        Module Access Restriction
                    </label>
                    <br></br>
                    <input className='px-radio' name="accessRestriction" type={'checkbox'} checked={accessRestriction} onChange={(e) => onhandleCheckbox(e)} />  Access Restriction (Mark it only if access restriction is required)
                </div>
            )
        }
    }

    const renderGlobalEnabledReport = () => {
        if (globalEnabledModule) {
            return (
                <div className="col-12  col-xl-12 form-group tile-new">
                    <label className="">
                         Region
                    </label>  
                    <br></br>
                    <input className='px-radio' type={'checkbox'} name="globalRegion" checked={globalRegion} onChange={(e) => onhandleCheckbox(e)} />  Global Region
                 </div>
            )
        }
    }

    return (
        <div className="container">

            <div className="col-12 sections my-2" id="basic_screen">
                <form className="form">
                    <div className="row">

                        <div className="col-12  col-xl-12 form-group tile-new">
                            <label className="tile-new">
                                Child Module Name
                            </label>
                            <input value={moduleName} name="moduleName" type="text" className="form-control tile-new" placeholder="Label for module" onChange={(e) => UpdateFormInput(e)} />
                        </div>

                        <div className="col-12  col-xl-12 form-group tile-new">
                            <label className="tile-new">
                                Child Module Description <small>(This will be shown in the modules)</small>
                            </label>
                            <input value={description} name="description" type="text" className="form-control tile-new" placeholder="Enter description for the module." checked={true} onChange={(e) => UpdateFormInput(e)} />
                        </div>


                        {createNewChildModule()}
                        {moduleAccessRestriction()}
                        {/* { renderAdGroup() } */}
                        {renderGlobalEnabledReport()}

                        <div className="col-12  col-xl-12 form-group tile-new">
                            <label className="">Content Type</label>
                            {
                                reportEmbeddingType()
                            }
                            <br></br>
                            <input className='px-radio' type={'checkbox'} name="Link" checked={Link} onChange={(e) => onhandleCheckbox(e)} /> Link to KC platform
                        </div>

                        {renderPlatformLink()}

                        {renderConditionalFeature()}

                        {renderEventMessage()}
                        <div className="col-12  col-xl-12 form-group  flex mt-0" style={{ justifyContent: "flex-end" }}>
                            <button type="button" className="btn btn-apply" onClick={(e) => { e.preventDefault(); saveChildModuleDetails() }}>Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ChildModule;

