export default (state = { team: [] }, action) => {
  switch (action.type) {
    case "FETCH_OPT_ALERTS_TARGET_USER_TEAM_REQUEST":
      return {
        team: [],
        loading: true,
        userTeamLoading : true,
      };

    case "FETCH_OPT_ALERTS_TARGET_USER_TEAM_SUCCESS":
      return {
        team: action.payload,
        responseDefault: false,
        loading: false,
        success: true,
        error: false,
        userTeamLoading : false,
      };

    case "FETCH_OPT_ALERTS_TARGET_USER_TEAM_FAILURE":
      return {
        team: [],
        error: true,
        responseDefault: false,
        loading: false,
        success: false,
        userTeamLoading : false,
      };

    default:
      return state;
  }
};
