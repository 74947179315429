import apiInstance from '../network/index';
import checkWhite from '../assets/icons/checkWhite.svg';
import errorWhite from '../assets/icons/errorWhite.svg';



const utils = {
  deviceIsWeb: window.innerWidth >= 680,

  checkAPIfailure: res => {
    res = res.hasOwnProperty('data') ? res.data : res;
    if (!!res && res.hasOwnProperty('status') && res.status !== 200) {
      return res.data;
    } else {
      return res.data;
    }
  },

  apiWithTimeout: (httpReq, milliseconds) => {
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        reject(new Error("Data fetch failed in " + milliseconds + " ms"))
      }, milliseconds)
      httpReq.then(res => { resolve(res) }, err => { reject(err) })
    })
  },

  isValidArray: (data) => {
    if (Array.isArray(data) && data.length > 0) {
      return true;
    }
    else if (!Array.isArray(data)) {
      return false;
    }
  },

  dispatchGlobalError: (err, trigger, dispatch, type) => {
    let error = {};
    if (err.message === 'Network Error') {
      if (err.status === 500) {
        error.message = err.message;
        error.alertTrigger = true;
        dispatch({ type: 'GLOBAL_API_FAILURE', payload: error });
      }
      else {
        error.message = err.message;
        error.alertTrigger = false;
        dispatch({ type: 'GLOBAL_NETWORK_FAILURE', payload: error });
      }
    }
    else if (err.message === 'Request failed with status code 404') {
      error.message = err.message;
      error.alertTrigger = true;
      dispatch({ type: 'API_NOT_FOUND_FAILURE', payload: error })
    }
    else if (err.message === "Request failed with status code 400" && !!trigger) {
      error.message = err.message;
      error.userMessage = err.response.data.usermessage;
      error.alertTrigger = true;
      dispatch({ type: 'GLOBAL_API_FAILURE', payload: error })
    }
    else {
      dispatch({ type: type, payload: err })
    }
  },

  toastTriggered: (apiResponse, dispatch, trigger) => {
    const { error, defaultResponse, message } = apiResponse;
    let toastProperties = {
      id: 4759834, autoDeleteTime: 2500, autoDelete: true, trigger: false,
    };
    if (!!error && !defaultResponse) {
      toastProperties.description = message;
      toastProperties.title = 'Failure';
      toastProperties.icon = errorWhite;
      toastProperties.trigger = trigger;
      toastProperties.backgroundColor = '#d9534f';
      toastProperties.status = 'error';
      dispatch({ type: 'TOAST_TRIGGERED', payload: [toastProperties] });
    }
    else if (!error && !defaultResponse) {
      toastProperties.description = message;
      toastProperties.title = 'Success';
      toastProperties.backgroundColor = '#5cb85c';
      toastProperties.icon = checkWhite;
      toastProperties.trigger = trigger;
      toastProperties.status = 'success';
      dispatch({ type: 'TOAST_TRIGGERED', payload: [toastProperties] });
    };
  },

  userTrackingInfo: (params) => {
    let sessionData = JSON.parse(sessionStorage.getItem('oktaRes'));
    let userParams = params;
    userParams["userName"] = sessionData && sessionData.name;
    userParams["userEmail"] = sessionData && sessionData.email;
    userParams["userGroup"] = sessionData && sessionData.KC_Group && Array.isArray(sessionData.KC_Group) ? sessionData.KC_Group[0] : null;
    userParams["sessionId"] = sessionData && sessionData.sessionId;
    return apiInstance.post(`/api/users`, { userInfo: userParams })
      .then(res => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  },

  powerBiFailure: (url, params) => {
    return apiInstance.post(`/api/${url}`, params)
      .then(res => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  },

  sortBy: (arr, sortByKey) => {
    return arr.sort((a, b) => {
      return a[sortByKey] < b[sortByKey] ? -1 : a[sortByKey] > b[sortByKey] ? 1 : 0;
    });
  },

  uniqueData: (filter) => {
    return Array.isArray(filter) &&
      filter.reduce((unique, o) => {
        if (!unique.some(obj => obj.category === o.category)) {
          unique.push(o);
        }
        return unique;
      }, []);
  },

  uniqueFilterData: (filter) => {
    return Array.isArray(filter) &&
      filter.reduce((unique, o) => {
        if (!unique.some(obj => obj.values['0'] === o.values['0'])) {
          unique.push(o);
        }
        return unique;
      }, []);
  },

  uniqueArrayData: (arr, key) => {
    return Array.isArray(arr) &&
      arr.reduce((unique, o) => {
        if (!unique.some(obj => obj[key] == o[key])) {
          unique.push(o);
        }
        return unique;
      }, []);
  },

  uniqueAdGroup: (originalArray, prop) => {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }

    return newArray;
  },

  validURL: (url) => {
   var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(url);
  },

  checkValidPBIds: (htmlTemp, customizableFlag) => {
    if (customizableFlag) {
      const element = !!htmlTemp && Object.keys(htmlTemp).length > 0 && Object.keys(htmlTemp).filter(key => htmlTemp[key] && htmlTemp[key].type === "dashboard");
      if (element && htmlTemp[element]) {
        const params = {
          groupId: htmlTemp[element].groupId,
          reportId: htmlTemp[element].reportId
        }
        return params;
      }
      if (htmlTemp) {
        const params = {
          groupId: htmlTemp.groupId,
          reportId: htmlTemp.reportId
        }
        return params;
      }
    }
    else {
       if (htmlTemp) {
        const params = {
          groupId: htmlTemp.groupId,
          reportId: htmlTemp.reportId
        }
        return params;
      }
    }
  },

  uniqueRegionBuId : (RegionMappingData) =>{
    let { data } = RegionMappingData;
    let regionBuId = [];
    if(Array.isArray(data) && data.length> 0){
      let usergroup = data.filter((element) => element.userGroup.includes(process.env.REACT_APP_OKTA_ENV));
      let uniqueIds = utils.uniqueAdGroup(usergroup, 'userGroup');
      uniqueIds.forEach((el)=> 
          regionBuId.push(el.regionBuId)
      )
      return regionBuId;
    }
  },
 
  selectedRegionParams: (regions, defaultRegion) => {
    if (Array.isArray(regions) && regions.length > 0) {
      let index = regions.findIndex(el => el.region === defaultRegion)
      if (index > -1) {
        let subRegion = regions[index].subRegion;
        let Region = regions[index];
        return { subRegion, Region }
      }
    }
  },

  getRegion: (groups) => {
    let regionContributor = '';
    if (Array.isArray(groups) && groups.length > 0) {
      regionContributor = groups[0].split('_');
      return regionContributor[4];
    }
    return regionContributor[4];
  },

  prepareRegionList: (groups, pageType) => {
    let region = [];
    const toSearch = '_Global_';
    let regionContributor = '';
    if (Array.isArray(groups) && groups.length > 0) {
      const globalAdGroup = groups.filter(item => item.search(toSearch) > -1);
      if (Array.isArray(globalAdGroup) && globalAdGroup.length > 0) {
        regionContributor = globalAdGroup[0].split('_');
        if (pageType === "Resources") {
          ["Global", "APAC", "EMEA", "KCNA", "LAO"].map((name, index) => {
            let oktaConfig = {};
            oktaConfig.reportType = regionContributor[3];
            oktaConfig.region = name;
            oktaConfig.environment = regionContributor[5];
            oktaConfig.adminType = regionContributor[6];
            oktaConfig.rowKey = index;
            region.push(oktaConfig);
            return region;
          });
        }
        else if (pageType === "Playbooks") {
          ["APAC ASEAN", "APAC ANZ", "APAC CHINA", "APAC INDIA", "APAC HKTW", "APAC KOREA", "EMEA", "KCNA", "LAO"].map((name, index) => {
            let oktaConfig = {};
            oktaConfig.reportType = regionContributor[3];
            oktaConfig.region = name;
            oktaConfig.environment = regionContributor[5];
            oktaConfig.adminType = regionContributor[6];
            oktaConfig.rowKey = index;
            region.push(oktaConfig);
            return region;
          });
        }
        else if ((pageType !== "Resources" || pageType === undefined) && regionContributor[4] === 'Global') {
          ["APAC", "EMEA", "KCNA", "LAO"].map((name, index) => {
            let oktaConfig = {};
            oktaConfig.reportType = regionContributor[3];
            oktaConfig.region = name;
            oktaConfig.environment = regionContributor[5];
            oktaConfig.adminType = regionContributor[6];
            oktaConfig.rowKey = index;
            region.push(oktaConfig);
            return region;
          });
        }
      }
      else {
        groups.map((KCgroup, rowIndex) => {
          regionContributor = KCgroup.split('_');
          if (pageType === "Resources") {
             ["Global", regionContributor[4]].map((name, index) => {
              let oktaConfig = {};
              oktaConfig.reportType = regionContributor[3];
              oktaConfig.region = name;
              oktaConfig.environment = regionContributor[5];
              oktaConfig.adminType = regionContributor[6];
              oktaConfig.rowKey = index;
              region.push(oktaConfig);
              return utils.uniqueData(region);
            });
          }
          else if (pageType === "Playbooks" && regionContributor[4] !== 'Global') {
            ["APAC ASEAN", "APAC ANZ", "APAC CHINA", "APAC INDIA", "APAC HKTW", "APAC KOREA", regionContributor[4]].map((name, index) => {
              let oktaConfig = {};
              oktaConfig.reportType = regionContributor[3];
              oktaConfig.region = name;
              oktaConfig.environment = regionContributor[5];
              oktaConfig.adminType = regionContributor[6];
              oktaConfig.rowKey = index;
              if (name !== "APAC") {
                region.push(oktaConfig);
              }
              return region;
            });
           }
          else if (regionContributor[4] !== 'Global') {
            let oktaConfig = {};
            oktaConfig.reportType = regionContributor[3];
            oktaConfig.region = regionContributor[4];
            oktaConfig.environment = regionContributor[5];
            oktaConfig.adminType = regionContributor[6];
            oktaConfig.rowKey = rowIndex;
            region.push(oktaConfig);
          }
          return utils.uniqueData(region);
        })
      };
    }
    return utils.uniqueData(region);
  },
};
export default utils;