import React from 'react';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import Tooltip from '@material-ui/core/Tooltip';
import {getOperationalAlerts} from '../../redux/actions/index';
import './style.css';
import { useDispatch } from 'react-redux';

export default function DataTable(props) {
    const dispatch = useDispatch();

    const getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTableBodyCell: {
                root: {
                    backgroundColor: "#FF000",
                    fontFamily: 'Gilroy-Medium',
                },
                paper: {
                    boxShadow: "none",
                    borderRadius: 0
                },
            },
            MuiTableHead: {
                root: {
                    backgroundColor: "#000000",
                    fontFamily: 'Gilroy-Medium',
                }
            },
            MuiTableCell: {
                head: {
                    color: '#000',
                    fontWeight: 'bold',
                    background: '#e5e5e5',
                    fontFamily: 'Gilroy-Medium',
                }
            },
            MuiToolbar: {
                root: {
                    borderBottom: '2px solid #e5e5e5',
                    fontFamily: 'Gilroy-Medium',
                },
            }
        }
    });


    const refreshReport = () => {
        dispatch(getOperationalAlerts());
    };

    const { loading,   error, data, title, columns, margin, } = props;

    const options = {
        customToolbar: () => {
            return <div className="icon">
                <Tooltip title="Refresh" aria-label="Refresh">
                    <RefreshIcon onClick={() => refreshReport()} style={{fill: "#757272", cursor: 'pointer'}}/>
                </Tooltip>
            </div>
        },
        filter: true,
        filterType: "dropdown",
        responsive: 'vertical',
        tableBodyHeight: '400px',
        rowsPerPage: '15',
        print: false,
        selectableRows: false,
        resizableColumns:  loading ? false : true,
        download: true
    };


    return (
        <div style={{ width: '100vw', margin: margin }}>
            <MuiThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    title={title}
                    data={!!error ? 'No Data Available' : !!loading ? [{subCategory : 'Please Wait...'}] : data}
                    columns={columns}
                    options={options}
                    responsive={'scroll'}
                />
            </MuiThemeProvider>
        </div>
    );
};



