import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from '../../component/dateTimePicker/datePicker';
import TimePicker from '../../component/dateTimePicker/timePicker';
import MultipleSelectCheckmarks from '../../component/multipleSelect';
import {
    createOperationalAlerts,
    OperationalAlertsTypes,
    fetchAlertField,
    toastTriggered,
    updateAlertFieldData,
    fetchOptAlertTargetUser,
    publishOperationalAlerts,
    updateOperationalAlerts,
    fetchOptAlertTargetUserCountry,
    fetchOptAlertTargetUserTeam,
    fetchRegionalMappingData,
} from '../../redux/actions/index';
import check from '../../assets/icons/check.svg';
import cloneDeep from "lodash/cloneDeep";
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import PublicIcon from '@material-ui/icons/Public';
import Loading from '../../component/Spinner';
import Tooltip from '@material-ui/core/Tooltip';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import IconButton from '@material-ui/core/IconButton';
import checkWhite from '../../assets/icons/checkWhite.svg';
import utils from '../../utils/index';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

let toastProperties = {
    title: 'Success',
    description: null,
    backgroundColor: '#5cb85c',
    percentageDownload: '100%',
    icon: checkWhite,
    status: 'success',
    autoDeleteTime: 3000,
    autoDelete: true,
    trigger: true,
};

const CreateOperationalAlert = (props) => {
    const dispatch = useDispatch();

    let fromDate = new Date().toISOString().split('T')[0];
    let d = new Date().toISOString().split('T')[0];
    let date = new Date(d);
    let toDate = new Date(date.setDate(date.getDate() + 0)).toISOString().split('T')[0];

    var t = new Date().getTime() + (1 * 60 * 60 * 1000);
    let time = new Date(t);
    var startTime = new Date().getHours() + ":" + new Date().getMinutes();
    var endTime = time.getHours() + ":" + time.getMinutes() + ":" + time.getSeconds();

    const [state, setState] = useState({});
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [checked, setChecked] = useState(false);
    const [targetUser, setTargetUser] = useState([]);
    const [userSelect, setUserSelect] = useState(false);
    const [searchItem, setSearchItem] = useState('');
    const [assignUser, setAssignUser] = useState(false);
    const [freeze, setFreeze] = useState(false);
    const [selectedFromDate, setSelectedFromDate] = useState(fromDate);
    const [selectedToDate, setSelectedToDate] = useState(toDate);
    const [applicableFilter, setApplicableFilter] = useState([]);
    const [filterItem, setFilterItem] = useState({});
    const [filterApplied, setAppliedFilter] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [mappingData, setMappingData] = useState({});

    const [region, setRegion] = useState([]);
    const [userCountry, setUserCountry] = useState([]);
    const [userTeam, setUserTeam] = useState([]);

    const alertField = useSelector((state) => state.alertField);
    const alertType = useSelector((state) => state.alertType);
    const createAlert = useSelector((state) => state.createAlert);
    const updateAlert = useSelector((state) => state.updateAlert);
    const configureUser = useSelector((state) => state.optAlertTargetUser);
    const targetUserCountry = useSelector((state) => state.targetUserCountry);
    const targetUserTeam = useSelector((state) => state.targetUserTeam);
    const selectedRegion = useSelector((state) => state.getBusinessUnitsRegion);
    const publishAlerts = useSelector((state) => state.publishOperationalAlerts);
    const alertFilter = useSelector((state) => state.alertFilter);
    const { activeRoutes } = useSelector((state) => state.activeDynamicData);
    const regionalMappingData = useSelector((state) => state.regionalMappingData);


    const { field } = alertField;
    const { regionList } = selectedRegion;
    const { userCountryLoading } = targetUserCountry;
    const { userTeamLoading } = targetUserTeam;

    useEffect(() => {
        dispatch(fetchAlertField());
        dispatch(OperationalAlertsTypes());
    }, [dispatch]);

    useEffect(() => {
        setState({ ...state, startDate: fromDate, endDate: toDate, endTime: endTime, });
    }, [endTime, fromDate, toDate]);

    useEffect(() => {
        if (state.region && selectedCategory) {
            let param = {
                region: state.region,
                component: selectedCategory
            }
            dispatch(fetchRegionalMappingData(param));
        }
    }, [dispatch, state.region, selectedCategory,]);

    useEffect(() => {
        let alertCreated = Array.isArray(createAlert.data) && createAlert.data.length > 0 ? true : false;
        let response = !!alertCreated && !!assignUser ? publishAlerts : !!alertCreated && !assignUser ? updateAlert : createAlert;
        const { error, defaultResponse } = response;
        if (!!error && !defaultResponse) {
            utils.toastTriggered(response, dispatch, true);
            setAssignUser(false);
        }
    }, [assignUser, createAlert, dispatch, publishAlerts, updateAlert]);

    useEffect(() => {
        let regions = ['APAC', 'EMEA', 'LAO', 'KCNA', 'Global']
        let userRegion = state.region === 'Global' ? regions : state.region;
        let countryRegion = state.region === 'Global' ? regions : [state.region]
        let params = { region: userRegion }
        if (Array.isArray(createAlert.data) && createAlert.data.length > 0 && createAlert.data[0].inserted_alert_id) {
            dispatch(fetchOptAlertTargetUser(params));
            let countryParams = countryRegion;
            dispatch(fetchOptAlertTargetUserCountry(countryParams));
        }
    }, [createAlert.data, dispatch, state.region]);

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    useEffect(() => {
        let { country } = targetUserCountry;
        let data = Array.isArray(country) && country.filter((el) => !!el['User Base Country']);
        setUserCountry(data);
        if (Array.isArray(data) && data.length > 0) {
            let renderDataList = data.map((item) => {
                return Object.values(item);
            });
            let filteredData = renderDataList.flat();
            var countires = filteredData.filter(onlyUnique);
             dispatch(fetchOptAlertTargetUserTeam(countires));
        }
    }, [dispatch, targetUserCountry]);

    useEffect(() => {
        let { team } = targetUserTeam;
        const data = Array.isArray(team) && team.filter((el) => !!el['Team']);
        if (Array.isArray(data) && data.length > 0) {
            setUserTeam(data);
        }
    }, [targetUserTeam]);

    useEffect(() => {
        const { users } = configureUser;
        if (!!users && Object.keys(users).length > 0) {
            setTargetUser(users.user);
        }
        else if (!!users && Object.keys(users).length === 0) {
            setTargetUser([]);
        }
    }, [configureUser]);

    useEffect(() => {
        const { data } = alertType;
        let alertRegions = cloneDeep(regionList);
        let global = {
            region: 'Global',
            subRegion: []
        }
        if (!!alertRegions && alertRegions.length > 0) {
            alertRegions.push(global);
            alertRegions.forEach((region) => {
                let keys = Object.keys(region);
                keys.forEach(key => {
                    if (key !== 'region') {
                        delete region[key]
                    }
                });
            });
            if (!!data && Object.keys(data).length > 0) {
                data.region = alertRegions;
            }
            let regions = state.region === 'Global' ? alertRegions : [{ region: state.region }, { region: 'Global' }];
            setRegion(regions);
        }
    }, [alertType, regionList, state.region]);

    useEffect(() => {
        let keyUser = [{ "Key User ": "No" }, { "Key User ": "Yes" }];
        let filterFields = [
            { name: 'region', label: 'region', appliedFilter: region, multiSelect: false },
            { name: 'userBaseCountry', label: 'User Base Country', appliedFilter: userCountry, multiSelect: true },
            { name: 'team', label: 'Team', appliedFilter: userTeam, multiSelect: true },
            { name: 'keyUser', label: 'Key User ', appliedFilter: keyUser, multiSelect: false },
        ];
        setApplicableFilter(filterFields);
        sessionStorage.setItem('alertFilter', JSON.stringify(filterFields));
    }, [region, userCountry, userTeam]);

    const validateMapping = useCallback((data, fieldType) => {
        if (!!state.region && Array.isArray(data[fieldType.activeItem]) && data[fieldType.activeItem].length === 0) {
            const { loading, defaultResponse } = regionalMappingData;
            if (!!loading && !defaultResponse) {
                fieldType.error = true;
                fieldType.message = "...loading";
            }
            else if (!loading && !defaultResponse) {
                fieldType.error = true;
                fieldType[fieldType.activeItem] = [];
                fieldType.message = `There is no ${fieldType.label} available under this region, Please Create!`;
            }
        }
        else if (!!state.region && Array.isArray(data[fieldType.activeItem]) && data[fieldType.activeItem].length > 0) {
            fieldType.error = false;
            fieldType[fieldType.activeItem] = data[fieldType.activeItem].filter((el) => el[fieldType.filterId] === fieldType.filterID);
        }
    }, [regionalMappingData, state.region]);

    const validateMappingFeature = useCallback((data, fieldType, dependencyMpping, mappingType,) => {
        const { loading, defaultResponse } = regionalMappingData;
        if (Array.isArray(data[fieldType.dependency]) && data[fieldType.dependency].length > 0) {
            let index = data[fieldType.dependency].findIndex((el) => el[dependencyMpping] == state[mappingType]);
             if (index > -1) {
                fieldType.error = false;
                if (Array.isArray(data[fieldType.dependency][index][fieldType.dependentItem]) && data[fieldType.dependency][index][fieldType.dependentItem].length > 0) {
                    fieldType[fieldType.activeItem] = data[fieldType.dependency][index][fieldType.dependentItem]
                    fieldType.error = false;
                }
                else {
                    fieldType.error = true;
                    fieldType.message = `There is no ${fieldType.label} available under this release, Please Create!!!`;
                }
            }
        }
        else if (!!state[mappingType] && !!loading && !defaultResponse && Array.isArray(data[fieldType.dependency]) && data[fieldType.dependency].length === 0) {
            fieldType.error = true;
            fieldType.message = "...loading";
            fieldType[fieldType.activeItem] = [];
        }
        else if (!!state[mappingType] && !loading && !defaultResponse && Array.isArray(data[fieldType.dependency]) && data[fieldType.dependency].length === 0) {
            fieldType[fieldType.activeItem] = [];
            fieldType.error = true;
            fieldType.message = `There is no ${fieldType.label} available under this region, Please Create!`;
            delete state[mappingType]
            delete state.releaseFeatures;
        }
    }, [regionalMappingData, state]);

    const validateAlertStartTime = useCallback((data, fieldType) => {
        if (fromDate === selectedFromDate && fieldType.activeItem === "startTime") {
            data[fieldType.activeItem].forEach((el) => {
                let str1 = el.time.split(':');
                let str2 = startTime.split(':');
                let totalSeconds1 = parseInt(str1[0] * 3600 + str1[1] * 60 + str1[0]);
                let totalSeconds2 = parseInt(str2[0] * 3600 + str2[1] * 60 + str2[0]);
                if (totalSeconds1 < totalSeconds2) {
                    el.disabled = true;
                }
            });
        }
        else if (fromDate !== selectedFromDate && fieldType.activeItem === "startTime") {
            data[fieldType.activeItem].forEach((el) => {
                el.disabled = false;
            });
        }
    }, [fromDate, selectedFromDate, startTime]);

    useEffect(() => {
        const { data, loading, defaultResponse } = alertType;
        const { mappingData } = regionalMappingData;
        if (Array.isArray(mappingData) && mappingData.length > 0) {
            if (state.category === "2") {
                data.releaseName = mappingData;
            }
            else if (state.category === "5") {
                data.quarter = mappingData;
            }
        }
        else if (Array.isArray(mappingData) && mappingData.length === 0) {
            data.releaseName = [];
            data.quarter = [];
        }
        Array.isArray(field) && field.length > 0 && !!data && Object.keys(data).length > 0 && !!regionList && regionList.length > 0 && !loading && !defaultResponse &&
            field.forEach((fieldType) => {
                if (fieldType.inputType === "select") {
                    if (!fieldType.dependent && !!fieldType.activeItem && !fieldType.nextItem) {
                        fieldType[fieldType.activeItem] = data[fieldType.activeItem];
                        validateAlertStartTime(data, fieldType);
                    }
                    else if (!fieldType.dependent && !!fieldType.activeItem && !!fieldType.nextItem) {
                        fieldType[fieldType.activeItem] = data[fieldType.activeItem];
                        let id = data[fieldType.activeItem][0][fieldType.value];
                        let nextDependentItem = field.filter((el) => el.name === fieldType.nextItem);
                        nextDependentItem[0]['filterID'] = id;
                    }
                    else if (!!fieldType.dependent && !!fieldType.activeItem && !!fieldType.nextItem && fieldType.dependentItem === 1) {
                        fieldType[fieldType.activeItem] = data[fieldType.activeItem].filter((el) => el[fieldType.filterId] === fieldType.filterID);
                    }
                    else if (!!fieldType.dependent && !!fieldType.activeItem && !!fieldType.nextItem && fieldType.dependentItem === 2) {
                        validateMapping(data, fieldType);
                    }
                    else if (!!fieldType.dependent && !!fieldType.activeItem && !fieldType.nextItem) {
                        if (fieldType.activeItem === "subCategory" || fieldType.dependency === "category") {
                            let index = data[fieldType.dependency].findIndex((el) => el.categoryID === state.category);
                            if (index > -1) {
                                fieldType[fieldType.activeItem] = data[fieldType.dependency][index][fieldType.activeItem]
                            }
                        }
                        else if (fieldType.activeItem === "newsletter" || fieldType.dependency === "quarter") {
                            validateMappingFeature(data, fieldType, 'quarter', 'quarter');
                        }
                        else if ((fieldType.activeItem === "releaseFeatures" || fieldType.dependency === "releaseName")) {
                            validateMappingFeature(data, fieldType, 'releaseNoteID', 'releaseName');
                        }
                        else {
                            fieldType[fieldType.activeItem] = data[fieldType.dependency][0][fieldType.activeItem]
                        }
                    }
                }
            });
        dispatch(updateAlertFieldData(field));
    }, [alertType, dispatch, regionalMappingData, field, regionList, state.category, validateMapping, validateAlertStartTime, validateMappingFeature]);

    const hanldleDateChange = (date, type) => {
        if (type === "Alert Date") {
            if (state.hasOwnProperty('alertRepeatFrequency') && state.alertRepeatFrequency !== '') {
                let d = new Date(date);
                let toDate = new Date(d.setDate(d.getDate() + parseInt(state.alertRepeatFrequency))).toISOString().split('T')[0];
                setSelectedToDate(toDate);
            }
            setSelectedFromDate(date);
            setState({ ...state, startDate: date });
        }
        else {
            setSelectedToDate(date);
            setState({ ...state, endDate: date });
        }
    };

    const handleTimeChange = (time, type) => {
        if (type === "Start Time") {
            setState({ ...state, startTime: time });
        }
        else {
            setState({ ...state, endTime: time });
        }
    };

    const handleInputChange = (e, fieldType) => {
        const { data } = alertType;
        let val = e.target.value;
        let name = e.target.name;
        if (fieldType.inputType === "input") {
            setState({ ...state, [name]: val });
            if (name === "alertRepeatFrequency") {
                let toDate = new Date(date.setDate(date.getDate() + parseInt(val))).toISOString().split('T')[0];
                setSelectedToDate(toDate);
             }
        }
        else if (fieldType.inputType === "select") {
            let name = e.target.name;
            let val = e.target.value;
            if (!fieldType.dependent && !!fieldType.activeItem && !!fieldType.nextItem && !fieldType.disable) {
                const items = data[fieldType.nextItem].filter((item) => item[fieldType.value] === val);
                let index = field.findIndex((el) => el.name === fieldType.nextItem);
                field[index][fieldType.nextItem] = items;
            }
            else if (!!fieldType.dependent && !!fieldType.activeItem && !!fieldType.nextItem && !fieldType.disable) {
                const sel = data[fieldType.activeItem].find((item) => item[fieldType.value] == val);
                let index = field.findIndex((el) => el.name === fieldType.nextItem);
                let hiddenIndex2 = field.findIndex((el) => el.name === fieldType.nextItem2);
                let hiddenIndex3 = field.findIndex((el) => el.name === fieldType.nextItem3);
                let hiddenIndex4 = field.findIndex((el) => el.name === fieldType.nextItem4);
                let hiddenIndex5 = field.findIndex((el) => el.name === fieldType.nextItem5);
                if (fieldType.name === "releaseName") {
                    let obj = {};
                    obj.releaseMappingName = sel.releaseName;
                    obj.releaseNoteID = sel.releaseNoteID;
                    setMappingData(obj);
                }
                if ((hiddenIndex2 > -1 && hiddenIndex3 > -1 && sel.category === "Release Notes")) {
                    field[hiddenIndex2].display = true;
                    field[hiddenIndex3].display = true;
                    field[hiddenIndex2].required = true;
                    field[hiddenIndex3].required = true;
                    setSelectedCategory(sel.category);
                }
                else if (hiddenIndex2 > -1 && hiddenIndex3 > -1 && sel.category !== "Release Notes") {
                    field[hiddenIndex2].display = false;
                    field[hiddenIndex3].display = false;
                    field[hiddenIndex2].required = false;
                    field[hiddenIndex3].required = false;
                    setSelectedCategory(sel.category);
                }
                if (hiddenIndex4 > -1 && hiddenIndex5 > -1 && sel.category === "Newsletter") {
                    field[hiddenIndex4].display = true;
                    field[hiddenIndex5].display = true;
                    field[hiddenIndex4].required = true;
                    field[hiddenIndex5].required = true;
                    setSelectedCategory(sel.category);
                }
                else if (hiddenIndex4 > -1 && hiddenIndex5 > -1 && sel.category !== "Newsletter") {
                    field[hiddenIndex4].display = false;
                    field[hiddenIndex5].display = false;
                    field[hiddenIndex4].required = false;
                    field[hiddenIndex5].required = false;
                    setSelectedCategory(sel.category);
                }
                field[index][fieldType.nextItem] = sel[fieldType.nextItem];
                state[fieldType.nextItem] = '';
                field[index].error = true;
                field[index].userInput = '';
            }
            else if (!fieldType.dependent && !!fieldType.activeItem && !!fieldType.nextItem && !!fieldType.disable) {
                let index = field.findIndex((el) => el.name === fieldType.nextItem);
                let hiddenItemIndex = field.findIndex((el) => el.name === fieldType.nextItem2);
                let colAdjust1 = field.findIndex((el) => el.name === fieldType.colAdjust1);
                let colAdjust2 = field.findIndex((el) => el.name === fieldType.colAdjust2);
                if (index > -1 && val === "false") {
                    field[index].disable = true;
                    field[index].required = false;
                    if (state[fieldType.nextItem] !== '') {
                        delete state[fieldType.nextItem];
                    }
                    if (hiddenItemIndex > -1) {
                        field[hiddenItemIndex].display = false;
                        field[colAdjust1].col = 3;
                        field[colAdjust2].col = 3;
                    }
                }
                else if (index > -1 && val === "true") {
                    field[index].disable = false;
                    field[index].required = true;
                    field[index].error = true;
                    if (hiddenItemIndex > -1) {
                        field[hiddenItemIndex].display = true;
                        field[colAdjust1].col = 2;
                        field[colAdjust2].col = 2;
                    }
                }
            }
            else if (fieldType.name === "releaseFeatures") {
                const sel = fieldType[fieldType.activeItem].find((item) => item[fieldType.value] == val);
                if (sel) {
                    let data = { releaseFeatures: sel.releaseFeatures, subCategoryId: sel.subCategoryId };
                    let obj = Object.assign(mappingData, data)
                    setMappingData(obj);
                }
            }
            else if (fieldType.name === "newsLetterName") {
                const sel = fieldType[fieldType.activeItem].find((item) => item[fieldType.value] == val);
                let data = { newsLetterName: sel.newsLetterName, newsLetterID: sel.newsLetterID };
                let obj = Object.assign(mappingData, data);
                setMappingData(obj);
            }
             setState({ ...state, [name]: val, });
        }
        if (!!fieldType.required && !val) {
            fieldType.error = true;
            fieldType.userInput = '';
        }
        else if (!!fieldType.required && !!val) {
            if (!!fieldType.dependent && !fieldType.disable && !!fieldType.display) {
                let index = field.findIndex((el) => el.name === fieldType.name);
                if (index > -1 && val > fieldType.maxDays) {
                    state.alertRepeatFrequency = '';
                    field[index].error = true;
                    field[index].required = true;
                    field[index].userInput = '';
                }
                else if (index > -1 && val < fieldType.minDays) {
                    state.alertRepeatFrequency = '';
                    field[index].error = true;
                    field[index].required = true;
                    field[index].userInput = '';
                }
                else {
                    field[index].error = false;
                    field[index].userInput = val;
                }
            }
            else {
                fieldType.error = false;
                fieldType.userInput = val;
            }
        }
    };

    let errorStyle = {
        border: '1px solid red',
    };

    const renderInput = (fieldType, index) => {
        if (!!fieldType.display) {
            if (fieldType.inputType === "input") {
                return (
                    <div className={`col-${fieldType.col} col-xl-${fieldType.col} form-group`} key={index}>
                        <label className="label-flex">{fieldType.label}
                            {
                                !!fieldType.required ?
                                    <span style={{ color: 'red', }}>*</span> : null
                            }</label>
                        <input
                            value={state[fieldType.name]}
                            type={fieldType.type}
                            name={fieldType.name}
                            className="alert-form-control"
                            placeholder={`Please input ${fieldType.label} ${fieldType.type === "number" ? `(in number of days & maximum days ${fieldType.maxDays}/ minimum days ${fieldType.minDays})` : ''}`}
                            onChange={(e) => handleInputChange(e, fieldType,)}
                            autoComplete="off"
                            disabled={fieldType.disable}
                            style={!!fieldType.required && !!fieldType.error ? errorStyle : null}
                        />
                    </div>
                )
            }
            else if (fieldType.inputType === "select") {

                return (
                    <div className={`col-${fieldType.col} col-xl-${fieldType.col} form-group`} key={index}>
                        <label className="label-flex">{fieldType.label}
                            {
                                !!fieldType.required ?
                                    <span style={{ color: 'red', }}>*</span> : null
                            }
                        </label>
                        <select
                            value={state[fieldType.name]}
                            className="alert-form-control"
                            name={fieldType.name}
                            onChange={(e) => handleInputChange(e, fieldType)}
                            style={!!fieldType.required && !!fieldType.error ? errorStyle : null}
                        >
                            <option value={""}>{"Select"}</option>
                            {Array.isArray(fieldType[fieldType.name])
                                && fieldType[fieldType.name].length > 0 &&
                                fieldType[fieldType.name].map((item, index) =>
                                (
                                    <option
                                        key={index}
                                        value={item[fieldType.value]}
                                        disabled={item['disabled']}
                                        className={item['disabled'] ? 'disabled' : 'options'}
                                    >
                                        {item[fieldType.name]}
                                    </option>
                                )
                                )
                            }
                        </select>
                        {!!fieldType.required && !!fieldType.error && fieldType.displayError ?
                            <div style={{ color: 'red', fontSize: '12px', textAlign: 'justify' }}>
                                {fieldType.message}
                            </div> : null
                        }
                    </div>
                )
            }
            else if (fieldType.inputType === "datePicker") {
                return (
                    <div className={`col-${fieldType.col}  col-xl-${fieldType.col} form-group `} key={index}>
                        <label className="label-flex mb-3">{fieldType.label}<span style={{ color: 'red', }}>*</span></label>
                        <DatePicker minFromDate={fromDate} minToDate={selectedToDate} startdate={selectedFromDate} endDate={selectedToDate} type={fieldType.label} hanldleDateChange={hanldleDateChange} />
                    </div>
                )
            }
            else if (fieldType.inputType === "timeStamp") {
                return (
                    <div className={`col-${fieldType.col}  col-xl-${fieldType.col} form-group `} key={index}>
                        <label className="label-flex">{fieldType.label}
                            <Tooltip title="This is GMT Time Zone" placement="top-start" style={{ padding: 0, display: 'none' }}>
                                <IconButton aria-label="ContactSupport">
                                    <ContactSupportIcon />
                                </IconButton>
                            </Tooltip>
                            <span style={{ color: 'red', }}>*</span>
                        </label>
                        <TimePicker type={fieldType.label} name={fieldType.name} startTime={startTime} endTime={endTime} handleTimeChange={handleTimeChange} />
                    </div>
                )
            }
        }
    };

    const filterData = (searchKey) => {
        const { users } = configureUser;
        let filter = users.user.filter(item =>
            (!!item['Email'] && item['Email'].toLowerCase().match(searchKey)) ||
            (!!item['First Name'] && item['First Name'].toLowerCase().match(searchKey)) ||
            (!!item['Last Name'] && item['Last Name'].toLowerCase().match(searchKey)) ||
            (!!item[' KC ID'] && item[' KC ID'].toLowerCase().match(searchKey)) ||
            (!!item['Region'] && item['Region'].toLowerCase().match(searchKey)) ||
            (!!item['User Base Country'] && item['User Base Country'].toLowerCase().match(searchKey)) ||
            (!!item['Team'] && item['Team'].toLowerCase().match(searchKey))
        );
        return filter;
    };

    const onChangeSearch = (e) => {
        const { users } = configureUser;
        const val = e.target.value;
        let searchKey = val.toLowerCase();
        setSearchItem(val);
        const filter = filterData(searchKey);
        if (val === '') {
            if (Array.isArray(targetUser) && targetUser.length >= 0) {
                setTargetUser(users.user);
                setChecked(false);
            }
        } else {
            setTargetUser(filter);
        };
    };

    const selectUsers = (index) => {
        const { users } = configureUser;
        let selectedUser = targetUser[index];
        if (!!selectedUser.isSelect) {
            selectedUser.isSelect = false;
        }
        else if (!selectedUser.isSelect) {
            selectedUser.isSelect = true;
        }
        if (!!checked) {
            let isSelected = users.user.filter((item) => !item.isSelect)
            if (isSelected.length > 0) {
                setChecked(false);
            }
        }
        else if (!checked) {
            let isSelected = users.user.filter((item) => !item.isSelect)
            if (isSelected.length === 0) {
                setChecked(true);
            }
        };
        setUserSelect(!userSelect);
        setTargetUser(targetUser);
    };

    const handleSelectAllUser = (e) => {
        let checked = e.target.checked;
        setChecked(e.target.checked);
        targetUser.forEach((item) => {
            if (!!checked) {
                item.isSelect = true;
            }
            else {
                item.isSelect = false;
            }
        });
        setTargetUser(targetUser);
    };

    const updateFilterItem = (name, values) => {
        alertFilter.metaData.map((item) => {
            if (item.key === name && values.length > 0) {
                  filterItem[name] = values;
            }
            else if (item.key === name && values.length === 0) {
                 delete filterItem[name];
            }
            else if (item.key !== name && Array.isArray(item.appliedFilter) && item.appliedFilter.length > 0) {
                 filterItem[item.key] = item.appliedFilter;
            }
            return item;
        });
        setFilterItem(filterItem);
        if (name === "region") {
            let index = alertFilter.metaData.findIndex(el => el.key === name);
            if (index > -1) {
                alertFilter.metaData[index].appliedFilter = values;
                let params = values;
                dispatch(fetchOptAlertTargetUserCountry(params));
            }
        } else if (name === "userBaseCountry") {
            let index = alertFilter.metaData.findIndex(el => el.key === name);
            if (index > -1) {
                alertFilter.metaData[index].appliedFilter = values;
                let params = values;
                 dispatch(fetchOptAlertTargetUserTeam(params));
            }
        }
    };

    const applyFilters = () => {
        let appliedFilter = {};
        alertFilter.metaData.map((item) => {
            if (filterItem && Object.keys(filterItem).length > 0 && !item.handleChangeCall) {
                 appliedFilter[item.key] = item.appliedFilter;
            }
            else if (filterItem && Object.keys(filterItem).length > 0 && !!item.handleChangeCall) {
                 appliedFilter[item.key] = filterItem[item.key];
            }
            else if (filterItem && Object.keys(filterItem).length === 0) {
                 appliedFilter[item.key] = item.appliedFilter;
            }
            return appliedFilter;
        });
        dispatch(fetchOptAlertTargetUser(appliedFilter)).then(()=>{
             let trackingObject = {
                region: state.region,
                actionType: 'Click',
                pageName: "Other Feature",
                reportName: "Alerts",
                reportSection: `Filters applied`,
                pageDetails: `${state.alertName}`,
            };
            utils.userTrackingInfo(trackingObject);
        });
        setAppliedFilter(false);
    };

    const resetFilters = () => {
        let alertFilter = JSON.parse(sessionStorage.getItem('alertFilter'));
        setApplicableFilter(alertFilter);
        if (Array.isArray(createAlert.data) && createAlert.data.length > 0 && createAlert.data[0].inserted_alert_id) {
            let regions = ['APAC', 'EMEA', 'LAO', 'KCNA', 'Global']
            let userRegion = state.region === 'Global' ? regions : state.region;
            let params = { region: userRegion }
            dispatch(fetchOptAlertTargetUser(params));
        }
    };

    const renderTargetUser = () => {
        return (
            <div className="col-12 col-xl-12">
                <div className="col-12 col-xl-12 flex">
                    <input
                        value={searchItem}
                        type={'search'}
                        name={'search'}
                        className="form-control alert-search"
                        onChange={(e) => onChangeSearch(e)}
                        autoComplete="off"
                        placeholder={`Please search by user's Name/ Email/ Region/ Country/ Team/ KCID`}
                    />
                </div>
                <div className="col-12 col-xl-12 mt-4 flex">
                    <div className="col-2 col-xl-2 panel-body" style={{ borderRight: '1px solid #e5e5e5' }}>
                        <div className="col-12 col-xl-12 panel-body mt-3">
                            <label className="label-flex" style={{ width: 'auto' }}> Please Filer the User </label>
                        </div>
                        <div className="col-12 col-xl-12 panel-body taleft">
                            {applicableFilter.map((item, index) => {
                                return (
                                    <div className="col-12 col-xl-12 panel-body" key={index}>
                                        {
                                            <MultipleSelectCheckmarks
                                                name={item.name}
                                                tag={item.label}
                                                data={item.appliedFilter}
                                                getFilterItem={updateFilterItem}
                                                targetUser={targetUser}
                                            />
                                        }
                                    </div>
                                )
                            })
                            }
                        </div>
                        <div className="col-12 col-xl-12 panel-body flex mt-5">
                            <div className="col-6 col-xl-6 pl-0" >
                                <button
                                    type="button"
                                    className="btn btn-primary btn-block"
                                    onClick={applyFilters}
                                    disabled={userCountryLoading || userTeamLoading}
                                >
                                    Apply Filter
                                </button>
                            </div>
                            <div className="col-6 col-xl-6">
                                <button
                                    style={{ margin: "0.4rem" }}
                                    type="button"
                                    className="btn btn-danger btn-block"
                                    onClick={resetFilters}
                                    disabled={userCountryLoading || userTeamLoading}
                                >
                                    Reset
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-10 col-xl-10 user-card-container">
                        {
                            targetUser.length > 0 ?
                                <div className="col-12 col-xl-12 panel-body flex">
                                    <div className="col-2 col-xl-2 panel-body flex  select-all-user">
                                        <input
                                            checked={checked}
                                            type={'checkbox'}
                                            name={'selectAllUser'}
                                            className="checkbox"
                                            onChange={(e) => handleSelectAllUser(e)}
                                            autoComplete="off"
                                        />
                                        Select All User
                                    </div>
                                    {
                                        searchItem !== '' ?
                                            <div className="col-3 col-xl-3 panel-body flex justify-end-content line-height select-all-user">
                                                Filtered Selected User : {targetUser.filter((item) => item.isSelect).length} out of {Array.isArray(targetUser) && targetUser.length}
                                            </div>
                                            : null
                                    }
                                    <div className="col-3 col-xl-3 panel-body flex justify-end-content line-height select-all-user">
                                        Total Selected User : {Array.isArray(configureUser.users.user) && configureUser.users.user.filter((item) => item.isSelect).length} out of {Array.isArray(configureUser.users.user) && configureUser.users.user.length}
                                    </div>
                                    <div className="col-4 col-xl-4 panel-body flex justify-end-content line-height">
                                        {!!error ? <div className="error"> {errorMessage} </div> : null}
                                    </div>
                                </div>
                                :
                                null
                        }
                        {
                            !!configureUser.loading ?
                                <div className="loaderPosition">
                                    <Loading />
                                    <div className="waitAlignment">Please Wait...</div>
                                </div>
                                : targetUser.length > 0 ?
                                    targetUser.map((user, index) => {
                                        return (
                                            <div key={index} className="col-3 col-xl-3 user-card-box" onClick={() => selectUsers(index)}>
                                                <div className="user-card" style={!!user.isSelect ? { border: '2px solid #19e519' } : null}>
                                                    <div className="col-12 col-xl-12 panel-body flex">
                                                        <div className="col-11 col-xl-11 panel-body desc user-name m-0 ">
                                                            <PermIdentityIcon style={{
                                                                height: '25px'
                                                            }} />
                                                            <span>
                                                                {user['First Name'] + ' ' + user['Last Name']}
                                                            </ span>
                                                        </div>
                                                        <div className="col-1 col-xl-1 m-0 greenCheck display-content">
                                                            {!!user.isSelect ? <img src={check} alt="userCheck" /> : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-xl-12 panel-body user-email">
                                                        <span><EmailOutlinedIcon /> {user.Email} </span></div>
                                                    <div className="col-12 col-xl-12 panel-body flex tile-font">
                                                        <div className="col-6 col-xl-6 desc panel-body user-country"> <span> <PublicIcon /> {user['User Base Country']} </span></div>
                                                        <div className="col-6 col-xl-6 desc user-id ta-end panel-body user-kcId"><span> KC ID: </span> {user[' KC ID']}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    !configureUser.loading && !!configureUser.error ?
                                        <div className="fontWeight">Sorry, There is no matching user records</div>
                                        : targetUser.length === 0 ?
                                            <div className="fontWeight">Sorry, There is no matching user records</div> : null
                        }
                    </div>
                </div>
            </div>
        )
    };

    const trackUserActivities = (type) => {
         let trackingObject = {
            region: state.region,
            actionType: 'Click',
            pageName: "Other Feature",
            reportName: "Alerts",
            reportSection: `Alert ${type}`,
            pageDetails: `${state.alertName}`,
        };
        utils.userTrackingInfo(trackingObject);
    };

    const selectTargetUser = () => {
        var allValueExist = false;
        let requiredField = Array.isArray(field) && field.filter((el) => !!el.required);
        Array.isArray(field) && field.forEach((item) => {
            if (!!item.required) {
                if (!state.hasOwnProperty(item.name)) {
                    if (item.name === "releaseName") {
                        if (Array.isArray(item[item.name]) && item[item.name].length === 0) {
                            item.message = "There is no release notes available in this region, Please Create!";
                        }
                    }
                    else if (item.name === "releaseFeatures") {
                        if (Array.isArray(item[item.name]) && item[item.name].length === 0) {
                            item.message = "There is no release feature available under this release, Please Create!";
                        }
                    }
                    else {
                        item.message = `Please ${item.inputType} ${item.label}`;
                    }
                    setError(true);
                    item.error = true;
                    item.userInput = '';
                }
                else if (!!state.hasOwnProperty(item.name) && state[item.name] !== '') {
                    item.error = false;
                    item.message = `Please ${item.inputType} ${item.label}`;
                    item.userInput = state[item.name];
                }
                 let requiredValue = Array.isArray(field) && field.filter((el) => !!el.required && el.userInput !== '');
                 if (!!item.error) {
                    allValueExist = false;
                    return true;
                }
                else if (state.hasOwnProperty('alertRepeatFrequency') && state.alertRepeatFrequency !== '' && (state.alertRepeatFrequency > 90 || state.alertRepeatFrequency < 1)) {
                    allValueExist = false;
                    return true;
                }
                else if (state.hasOwnProperty('autoDeletePeriod') && state.autoDeletePeriod !== '' && (state.autoDeletePeriod > 30 && state.alertRepeatFrequency < 1)) {
                    allValueExist = false;
                    return true;
                }
                else if (requiredValue.length === requiredField.length && !item.error && item.userInput !== '') {
                    allValueExist = true;
                    return false;
                }
            }
        });
        if (!!allValueExist) {
            setFreeze(true);
            let params = {
                alertDetails: {
                    alertName: state.alertName,
                    alertTypeID: state.alertType,
                    // categoryID: state.category,
                    subCategoryID: state.subCategory,
                    displayMessage: state.displayMessage,
                    startDate: selectedFromDate,
                    startTime: state.startTime,
                    endDate: selectedToDate,
                    endTime: endTime,
                    region: state.region,
                    alertRepeat: state.alertRepeat,
                    autoDelete: state.autoDelete,
                    mappingName: selectedCategory === "Release Notes" ? mappingData.releaseMappingName : state.quarter,
                    features: selectedCategory === "Release Notes" ? mappingData.releaseFeatures : mappingData.newsLetterName,
                    mappingID: selectedCategory === "Release Notes" ? state.releaseName : state.quarter,
                    featuresID: selectedCategory === "Release Notes" ? state.releaseFeatures : state.newsLetterName,
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                }
            };
            if (!!state.alertRepeatFrequency && state.alertRepeatFrequency !== '') {
                params.alertDetails.alertRepeatFrequency = state.alertRepeatFrequency
            }
            if (!!state.autoDeletePeriod && state.autoDeletePeriod !== '') {
                params.alertDetails.autoDeletePeriod = state.autoDeletePeriod
            }
            if (!!state.subRegion && state.subRegion !== '') {
                params.alertDetails.subRegion = state.subRegion
            }
            if (!!state.alertDescription && state.alertDescription !== '') {
                params.alertDetails.alertDescription = state.alertDescription
            }
            if (Array.isArray(createAlert.data) && createAlert.data.length > 0 && createAlert.data[0].inserted_alert_id) {
                let updatedParams = {
                    alertID: createAlert.data[0].inserted_alert_id,
                    alertDetails: params.alertDetails
                }
                dispatch(updateOperationalAlerts(updatedParams)).then((res) => {
                    setFreeze(false);
                    toastProperties['description'] = res.data.message;
                    dispatch(toastTriggered([toastProperties]));
                    setAssignUser(true);
                    setError(false);
                    trackUserActivities('Updated');
                })
            }
            else {
                dispatch(createOperationalAlerts(params)).then((res) => {
                    setFreeze(false);
                    toastProperties['description'] = res.data.message;
                    dispatch(toastTriggered([toastProperties]));
                    setAssignUser(true);
                    setError(false);
                    trackUserActivities('Created');
                })
            }
        }
    };

    const goBack = () => {
        setAssignUser(false);
        setAppliedFilter(false);
    };

    const cancelNotification = () => {
        props.history.push(activeRoutes.key10);
        setAssignUser(false);
    };

    const publishNotification = () => {
        let emails = [];
        let selectedUser = Array.isArray(configureUser.users.user) && configureUser.users.user.filter((el) => {
            if (el.isSelect) {
                let val = { email: el.Email, };
                return emails.push(val);
            }
            return el.isSelect;
        }
        );
        if (Array.isArray(selectedUser) && selectedUser.length === 0) {
            setError(true);
            setErrorMessage('***Selected user should be minimum 1 / maximum can be all user')
        }
        else {
            setFreeze(true);
            setError(false);
            let params = {
                "publishDetails": {
                    "alertID": Array.isArray(createAlert.data) && createAlert.data.length > 0 && createAlert.data[0].inserted_alert_id,
                    "alertName": state.alertName,
                    "region": state.region,
                    "subCategoryID": state.subCategory,
                },
                "users": emails,
                "userFilters": {
                    "region": state.region,
                    "userBaseCountry": filterItem['User Base Country'],
                    "team": filterItem['Team'],
                    "keyUser": filterItem['Key User ']
                }
            };
            dispatch(publishOperationalAlerts(params)).then((res) => {
                trackUserActivities('Published');
                toastProperties['description'] = res.data.message;
                dispatch(toastTriggered([toastProperties]));
                setAssignUser(true);
                setFreeze(false);
                props.history.push(activeRoutes.key10);
                dispatch({ type: "CREATE_OPERATIONAL_ALERTS_REQUEST" });
                dispatch({ type: "UPDATE_OPERATIONAL_ALERTS_REQUEST" });
            })
        }
    };

    return (
        <div className={"container-fluid alert-container panel-body"}>
            {
                <Backdrop open={freeze} style={{ zIndex: 10000, color: "#fff" }}>
                    <CircularProgress color="primary" />
                    <div
                        style={{
                            position: "relative",
                            top: "29px",
                            left: " -55px",
                            fontSize: "15px",
                        }}
                    >
                        Please Wait...
                    </div>
                </Backdrop>
            }
            <div className="col-12 alertHeader">New Alert Configuration</div>
            {!assignUser ?
                <div className="col-12 col-xl-12 flex">
                    <div className="col-1 col-xl-1"></div>
                    <div className="col-10 col-xl-10 form">
                        <form className="col-12 col-xl-12 alert-create-section">
                            {
                                Array.isArray(field) && field.length === 0 ?
                                    <div className="loaderPosition">
                                        <Loading />
                                        <div className="waitAlignment">Please Wait...</div>
                                    </div>
                                    :
                                    Array.isArray(field) && field.map((inputType, index) => {
                                        return renderInput(inputType, index)
                                    })
                            }
                        </form>
                    </div>
                    <div className="col-1 col-xl-1"></div>
                </div>
                :
                <div className="col-12 col-xl-12">
                    {renderTargetUser()}
                </div>
            }
            <div
                className="col-12 col-xl-12 form-group flex"
                style={{ justifyContent: "flex-end", position: 'relative', bottom: 0 }}>
                {!assignUser ?
                    <button
                        style={{ margin: "0.4rem" }}
                        type="button"
                        className="btn btn-success"
                        onClick={() => selectTargetUser()}
                        disabled={freeze}
                    >
                        Select Target User
                    </button> :
                    <button
                        style={{ margin: "0.4rem" }}
                        type="button"
                        className="btn btn-success"
                        onClick={goBack}

                    >
                        Back
                    </button>
                }
                {!assignUser ? null :
                    <button
                        style={{ margin: "0.4rem" }}
                        type="button"
                        className="btn btn-primary"
                        onClick={() => publishNotification()}
                        disabled={freeze}
                    >
                        Publish Notification
                    </button>
                }
                <button
                    style={{ margin: "0.4rem" }}
                    type="button"
                    className="btn btn-danger"
                    onClick={() => cancelNotification()}

                >
                    Cancel
                </button>
            </div>
        </div>
    )
};

export default CreateOperationalAlert;

