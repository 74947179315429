import axios from 'axios';
import { Api } from "../../network/api";
import apiInstance from '../../network/index';
import utils from '../../utils';

var CancelToken = axios.CancelToken;
let source = CancelToken.source();
let cancel;

const apiSlowFunction = (dispatch, url) => {
    let myTimeout = setTimeout(function () {
        dispatch({ type: 'GLOBAL_API_SLOW', payload: { trigger: true, apiName: url } })
    }, 30000);
    return myTimeout;
};

const pbiApiSlowFunction = (dispatch, url) => {
    let myTimeout = setTimeout(function () {
        dispatch({ type: 'GLOBAL_API_SLOW', payload: { trigger: true, apiName: url } })
    }, 60000);
    return myTimeout;
};

//Reducer to test api
export const testingNetwork = (params) => async (dispatch) => {
    Api.testingAPI(params).then(
        res => dispatch({ type: "TEST_API_SUCCESS", payload: res }),
        err => {
            dispatch({ type: "TEST_API_FAILURE", payload: err })
        }
    );
};

export const playbooksRegionValidate  = (params) => async(dispatch) =>{
    dispatch({
        type: "PLAYBOOK_REGION_VALIDATE",
        payload: params,
    });
    return true;
}

//Reducer to fetch Regions
export const getRegions = (params) => async (dispatch) => {
    dispatch({ type: "GET_REGIONS_REQUEST" });
    let myTimeout = await apiSlowFunction(dispatch,  'Region List API')
     await Api.getRegion(params)
        .then(res => {
             clearTimeout(myTimeout);
            dispatch({ type: "GET_REGIONS_SUCCESS", payload: res });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } });
        })
        .catch(err => {
            clearTimeout(myTimeout);
            utils.dispatchGlobalError(err, false, dispatch, 'GET_REGIONS_FAILURE');
        });
};

//Reducer to fetch Regions
export const globalSearchItem = (params) => async (dispatch) => {
    dispatch({ type: "GLOBAL_SEARCH_QUERY_RESULT_REQUEST" });
    let myTimeout = await apiSlowFunction(dispatch,  'Global Search Result')
     await Api.globalSearchQuery(params)
        .then(res => {
             clearTimeout(myTimeout);
            dispatch({ type: "GLOBAL_SEARCH_QUERY_RESULT_SUCCESS", payload: res });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } });
        })
        .catch(err => {
            clearTimeout(myTimeout);
            utils.dispatchGlobalError(err, false, dispatch, 'GLOBAL_SEARCH_QUERY_RESULT_FAILURE');
        });
};

export const updateRegionSpecificConfigs = (activeBusinessUnit, activeRoutes, disabledFeatures, activeRegionSpecificData,   activeRegion,  ) =>
async (dispatch) => {
    dispatch({
        type: "ACTIVE_BUSINESS_UNITS",
        payload: activeBusinessUnit,
    });
    dispatch({
        type: "ACTIVE_ROUTES",
        payload: activeRoutes,
    });
    dispatch({
        type: "DISABLE_FEATURES",
        payload: disabledFeatures,
    });
    dispatch({
        type: "ACTIVE_REGION_SPECIFIC_CONFIGS",
        payload: activeRegionSpecificData,
    });
    dispatch({
        type: "ACTIVE_REGION",
        payload: activeRegion,
    });
    return true;
};

export const updateActivePage = (activePage) => async (dispatch) =>{
    console.log('1111updateActivePage',activePage )
    dispatch({
        type: "ACTIVE_PAGE",
        payload: activePage,
    });
}

export const updateActiveBusinessUnit = (businessUnits) => async (dispatch) =>{
    dispatch({
        type: "ACTIVE_BUSINESS_UNITS",
        payload: businessUnits,
    });
}

export const updateFirstTimeAppLoadStatus = (selection) => async (dispatch) => {
    dispatch({
        type: "FIRST_TIME_APP_LOAD",
        payload: selection,
    });

    return true;
};

export const updateMsalToken = (selection) => async (dispatch) => {
      dispatch({
          type: "ACTIVE_MSAL_TOKEN",
          payload: selection,
      });

      return true;
  };

  export const updateMsalTokenFlag = (selection, from) => async (dispatch) => {
      dispatch({
          type: "IS_MSAL_TOKEN_EXPIRED",
          payload: selection,
      });

      return true;
  };

export const toastTriggered = (params) => async (dispatch) => {
    dispatch({
        type: "TOAST_TRIGGERED",
        payload: params,
    });
    return true;
}

/*update the selected Region*/
export const updateBusinessUnitRegion = (selections) => async (dispatch) => {
     dispatch({
        type: "UPDATE_BUSINESS_UNIT_REGION",
        payload: selections,
    });
    return true;
};

export const updateBusinessUnitSubRegion = (selections) => async (dispatch) => {
    dispatch({
        type: "UPDATE_BUSINESS_UNIT_SUB_REGION",
        payload: selections,
    });
    return true;
};

export const updateRegionFilters = (selections) => async (dispatch) => {
    dispatch({
        type: "SET_REGION_FILTER",
        payload: selections,
    });
    return true;
};

export const resetPlaybooksRegionFilters = (selections) => async (dispatch) => {
    dispatch({
        type: "RESET_PLAYBOOKS_REGION_FILTER",
        payload: selections,
    });
    return true;
};

/*get the Side Bar Menu List*/
export const sidebarMenuList = (params) => async (dispatch) => {
    dispatch({ type: "GET_SIDEBAR_MENU_LIST_REQUEST" });
    const myTimeout = await apiSlowFunction(dispatch,  'SideBar Content')
    let request = await Api.sideBarMenuList(params).then(
        res => {
            clearTimeout(myTimeout);
            dispatch({ type: "GET_SIDEBAR_MENU_LIST_SUCCESS", payload: res });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        },
    )
        .catch(err => {
            clearTimeout(myTimeout);
            utils.dispatchGlobalError(err, false, dispatch, 'GET_SIDEBAR_MENU_LIST_FAILURE');
        })
    return request;
};

/*update the Side Bar Menu List*/
export const updatesidebarMenuList = (params) => async (
    dispatch
) => {
     dispatch({
        type: "SIDEBAR_MENU_LIST",
        payload: params,
    });
    return true;
};

/*update the Side Bar Menu List*/
export const updateActiveMenuList = (params) => async (
    dispatch
) => {
     dispatch({
        type: "UPDATE_ACTIVE_MENU_LIST",
        payload: params,
    });
    return true;
};

/*update the Side Bar Menu List*/
export const updateActiveSubMenuList = (params) => async (
    dispatch
) => {
    dispatch({
        type: "UPDATE_ACTIVE_SUBMENU_LIST",
        payload: params,
    });
    return true;
};

/*update the Side Bar Menu List*/
export const updateActiveReportSection = (params) => async (
    dispatch
) => {
    dispatch({
        type: "UPDATE_ACTIVE_REPORT_SECTION",
        payload: params,
    });
    return true;
};


/*update the View Power BI Report*/
export const updatePowerBIReportPage = (params) => async (dispatch) => {
    dispatch({
        type: "UPDATE_POWER_BI_REPORT_REQUEST",
        payload: params,
    });
    return true;
};

/*update the View Power BI Report*/
export const updatePowerBIReport = (params) => async (
    dispatch
) => {
    dispatch({
        type: "UPDATE_POWER_BI_REPORT",
        payload: params,
    });
    return true;
};

/*update the View Okta Auth*/
export const updateOktaAuth = (params) => async (
    dispatch
) => {
    dispatch({
        type: "UPDATE_OKTA_AUTH_STATUS",
        payload: params,
    });
    return true;
};

/*update the business unit params*/
export const updateReportSectionParams = (params) => async (
    dispatch
) => {
    dispatch({
        type: "UPDATE_REPORT_SECTION_PARAMS",
        payload: params,
    });
    return true;
};

/*update the business unit params*/
export const updateMenuItemViewState = (params) => async (
    dispatch
) => {
    dispatch({
        type: "UPDATE_MENU_ITEM_VIEW_STATE",
        payload: params,
    });
    return true;
};

/*get the pages content */
export const getAllPagesContent = (params) => async (dispatch) => {
    dispatch({ type: "GET_ALL_PAGE_CONTENT_REQUEST" });
    let myTimeout = await apiSlowFunction(dispatch,  'Page Content API')
    let request = await Api.getPageContent(params).then(
        res => {
            clearTimeout(myTimeout);
            dispatch({ type: "GET_ALL_PAGE_CONTENT_SUCCESS", payload: res });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        },
    )
        .catch(err => {
            clearTimeout(myTimeout);
            utils.dispatchGlobalError(err, false, dispatch, 'GET_ALL_PAGE_CONTENT_FAILURE');
        })
    return request;
};

/*get the PLAY books content */
export const getPlayBooksContent = (params) => async (dispatch) => {
    dispatch({ type: "GET_PLAYBOOKS_CONTENT_REQUEST" });
    const myTimeout = await apiSlowFunction(dispatch,   'Playbook Content/View/Levar Data API')
    await Api.getPlaybookContent(params).then(
        res => {
            clearTimeout(myTimeout);
            dispatch({ type: "GET_PLAYBOOKS_CONTENT_SUCCESS", payload: res });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        },
    )
        .catch(err => {
            clearTimeout(myTimeout);
            utils.dispatchGlobalError(err, false, dispatch, 'GET_PLAYBOOKS_CONTENT_FAILURE');
        });
};

/*get the PLAY books region level filters */
export const getPlayBooksRegionFilters = (params) => async (dispatch) => {
    dispatch({ type: "GET_PLAYBOOKS_REGION_FILTER" });
    const myTimeout = await apiSlowFunction(dispatch,  'Playbooks Regional Filter API')
    let request = await Api.getPlayBooksRegionFilters(params).then(
        res => {
            clearTimeout(myTimeout);
            dispatch({ type: "GET_PLAYBOOKS_REGION_FILTER_SUCCESS", payload: res });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        },
    )
        .catch(err => {
            clearTimeout(myTimeout);
            utils.dispatchGlobalError(err, true, dispatch, 'GET_PLAYBOOKS_REGION_FILTER_FAILURE');
        })
    return request;
};


/*get the Additional Reports content */
export const getReportContent = (params) => async (dispatch) => {
    dispatch({ type: "GET_REPORT_CONTENT_REQUEST" });
    const timer = await apiSlowFunction(dispatch,  'Report Content API')
    let request = await Api.getReportContent(params).then(
        res => {
            clearTimeout(timer);
            dispatch({ type: "GET_REPORT_CONTENT_SUCCESS", payload: { ...res, params }  });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        },
    )
        .catch(err => {
            clearTimeout(timer);
            utils.dispatchGlobalError(err, false, dispatch, 'GET_REPORT_CONTENT_FAILURE');
        })
    return request;
};

export const dynamicReportContent = (params) => async (dispatch) => {
    dispatch({ type: "GET_DYNAMIC_REPORT_CONTENT_REQUEST" });
    const timer = await apiSlowFunction(dispatch,  'Dynamic Report Content API')
    let request = await Api.dynamicReportContent(params).then(
        res => {
            clearTimeout(timer);
            dispatch({ type: "GET_DYNAMIC_REPORT_CONTENT_SUCCESS", payload: res });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        },
    )
        .catch(err => {
            clearTimeout(timer);
            utils.dispatchGlobalError(err, false, dispatch, 'GET_DYNAMIC_REPORT_CONTENT_FAILURE');
        })
    return request;
};


/*update the View Builder JSON*/
export const updateReportContent = (selections) => async (
    dispatch
) => {
    dispatch({
        type: "UPDATE_REPORT_CONTENT",
        payload: selections,
    });
    return true;
};


/*get the PLAY books Sub Reports content */
export const getPlayBooksContentPages = (selections) => async (dispatch) => {
     dispatch({ type: "GET_PLAYBOOKS_CONTENT_PAGES_REQUEST" });
    let request;
        const timer = await pbiApiSlowFunction(dispatch,  'PBI Report Pages API')
         await Api.getPlaybookContentPages(selections).then(
            res => {
                clearTimeout(timer);
                dispatch({ type: "GET_PLAYBOOKS_CONTENT_PAGES_SUCCESS", payload: { ...res, selections } });
                dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload:  { trigger: true, type: "ApiSlow" } })
                return Promise.resolve(res);
            },
        )
            .catch(async (err) => {
                clearTimeout(timer);
                utils.dispatchGlobalError(err, false, dispatch, 'GET_PLAYBOOKS_CONTENT_PAGES_FAILURE');
            })
    return request;
};

/*get the PLAY books Sub Reports content for MSAL based region */
export const getMsalBasedPlaybookContentPages = (selections) => async (dispatch) => {
    dispatch({ type: "GET_PLAYBOOKS_CONTENT_PAGES_REQUEST" });
    let request;
        const timer = await pbiApiSlowFunction(dispatch,  'KCNA Sub Reports API')
         await Api.getMsalBasedPlaybookContentPages(selections).then(
            res => {
                clearTimeout(timer);
                dispatch({ type: "GET_PLAYBOOKS_CONTENT_PAGES_SUCCESS", payload: { ...res, selections } });
                dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload:  { trigger: true, type: "ApiSlow" } })
                return Promise.resolve(res);
            },
        )
            .catch(async (err) => {
                clearTimeout(timer);
                utils.dispatchGlobalError(err, false, dispatch, 'GET_PLAYBOOKS_CONTENT_PAGES_FAILURE');
            })


    return request;
};

/*get the PLAY books Sub Reports content for MSAL based region */
export const getPlaybooksubReport = (selections) => async (dispatch) => {
    dispatch({ type: "GET_PLAYBOOKS_CONTENT_PAGES_REQUEST" });
    let request;
        const timer = await pbiApiSlowFunction(dispatch,  'KCNA Sub Reports API')
         await Api.playbookSubReports(selections).then(
            res => {
                clearTimeout(timer);
                dispatch({ type: "GET_PLAYBOOKS_CONTENT_PAGES_SUCCESS", payload: { ...res, selections } });
                dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload:  { trigger: true, type: "ApiSlow" } })
                return Promise.resolve(res);
            },
        )
            .catch(async (err) => {
                clearTimeout(timer);
                utils.dispatchGlobalError(err, false, dispatch, 'GET_PLAYBOOKS_CONTENT_PAGES_FAILURE');
            })


    return request;
};

/*get All pages for admin */
export const getAllPagesForAdmin = (params) => async (dispatch) => {
    dispatch({ type: "GET_ALL_ADMIN_VIEWS_REQUEST" });
    const timer = await apiSlowFunction(dispatch,  'All Admin Dashboard API',)
    let request = await apiInstance.post(`/api/getAllViews`, params).then
        (res => {
            clearTimeout(timer);
            dispatch({ type: "GET_ALL_ADMIN_VIEWS_SUCCESS", payload: res });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        },
        )
        .catch(err => {
            clearTimeout(timer);
            utils.dispatchGlobalError(err, false, dispatch, 'GET_ALL_ADMIN_VIEWS_FAILURE');
        })
    return request;
};

/*get the Selected View*/
export const getSelectedView = (params) => async (dispatch) => {
    dispatch({ type: "GET_SELECTED_VIEW_REQUEST" });
    const timer = await apiSlowFunction(dispatch,  'Selected Dashboard View API')
    let request = await apiInstance.post(`/api/getSelectedView`, params).then(
        res => {
            clearTimeout(timer);
            dispatch({ type: "GET_SELECTED_VIEW_SUCCESS", payload: res });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        },
    )
        .catch(err => {
            clearTimeout(timer);
            utils.dispatchGlobalError(err, false, dispatch, 'GET_SELECTED_VIEW_FAILURE');
        })
    return request;
};

//get embed token for a power bi report
export const getEmbedToken = (selections) => async (dispatch) => {
    cancel && cancel("for Deepak - Pass cancel message");
    dispatch({ type: "GET_EMBED_TOKEN_REQUEST" });
    const timer = await pbiApiSlowFunction(dispatch,  'Power BI Embed Token API')
    let request = await apiInstance.post(`api/getEmbedToken`, selections, {
        cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            cancel = c;
        })
    })
        .then((res) => {
            clearTimeout(timer);
            dispatch({ type: "GET_EMBED_TOKEN_SUCCESS", payload: { ...res, selections } });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } });
            return Promise.resolve(res);
        })
        .catch(async (err) => {
            if (axios.isCancel(err)) {
                source.cancel();
                clearTimeout(timer);
                dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } });
            }
            else {
                clearTimeout(timer);
                utils.dispatchGlobalError(err, false, dispatch, 'GET_EMBED_TOKEN_FAILURE');
            }
        });
    return request;
};

/*update the View Builder JSON*/
export const updateViewBuilderJSON = (selections) => async (
    dispatch
) => {
    dispatch({
        type: "UPDATE_VIEW_BUILDER_JSON",
        payload: selections,
    });
    return true;
};

/******  update the View Builder JSON */
export const updateCustomizedViewBuilderJSON = (selections) => async (
    dispatch
) => {
    dispatch({
        type: "UPDATE_CUSTOMIZED_VIEW_BUILDER_JSON",
        payload: selections,
    });
    return true;
};

/*update the Global Region*/
export const updateGlobalRegion = (selections) => async (
    dispatch
) => {
    dispatch({
        type: "UPDATE_GLOBAL_REGIONS",
        payload: selections,
    });
    return true;
};

/*update the Report bookmark state*/
export const updatePresistReport = (selections) => async (
    dispatch
) => {
    dispatch({
        type: "UPDATE_PRESIST_FEATURE",
        payload: selections,
    });
    return true;
};

//add new project
export const addNewProject = (params) => async (dispatch) => {
    dispatch({ type: "ADD_NEW_PROJECT_REQUEST" });
    const timer = await apiSlowFunction(dispatch,  '/api/admin/addNewProject', 'post', params, 'ADD_NEW_PROJECT_REQUEST', 'ADD_NEW_PROJECT_SUCCESS', 'ADD_NEW_PROJECT_FAILURE')
    let request = await apiInstance.post(`/api/admin/addNewProject`, params).then(
        res => {
            clearTimeout(timer);
            dispatch({ type: "ADD_NEW_PROJECT_SUCCESS", payload: res });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        },
    )
        .catch(err => {
            clearTimeout(timer);
            utils.dispatchGlobalError(err, false, dispatch, 'ADD_NEW_PROJECT_FAILURE');
        })
    return request;
};

//add new Resources
export const createNewResources = (params) => async (dispatch) => {
    dispatch({ type: "ADD_NEW_RESOURCES_REQUEST" });
    const timer = await apiSlowFunction(dispatch,  'Create New Resources API')
    let request = await apiInstance.post(`/api/addNewResource`, params).then(
        res => {
            clearTimeout(timer);
            dispatch({ type: "ADD_NEW_RESOURCES_SUCCESS", payload: res });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        },
    )
        .catch(err => {
            clearTimeout(timer);
            utils.dispatchGlobalError(err, false, dispatch, 'ADD_NEW_RESOURCES_FAILURE');
        })
    return request;
};

// Admin APIs
export const deleteDashboard = (params, callback) => async (dispatch) => {
    await apiInstance.post(`/api/admin/deleteSelectedView`, params)
        .then((res) => {
            callback(res);
        })
        .catch((err) => {
            callback(err);
        });
};

export const getAllRegionBuIds = (params) => async (dispatch) => {
    dispatch({ type: "REGION_MAPPING_DATA_REQUEST" });
    const timer = await apiSlowFunction(dispatch, 'Region Mapping Data')
    let request = await Api.regionBuId(params)
        .then(res => {
            clearTimeout(timer);
            dispatch({ type: "REGION_MAPPING_DATA_SUCCESS", payload: res });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        },
        )
        .catch(err => {
            clearTimeout(timer);
            utils.dispatchGlobalError(err, false, dispatch, 'REGION_MAPPING_DATA_FAILURE');
        })
    return request;
};

/*get the Module content */
export const parentBusinessMappedIds = (params) => async (dispatch) => {
    dispatch({ type: "PARENT_BUSINESS_MAPPED_UNIT_REQUEST" });
    let myTimeout = await apiSlowFunction(dispatch,  'Page Content API')
    let request = await Api.parentBuMappedId(params).then(
        res => {
            clearTimeout(myTimeout);
            dispatch({ type: "PARENT_BUSINESS_MAPPED_UNIT_SUCCESS", payload: res });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        },
    )
        .catch(err => {
            clearTimeout(myTimeout);
            utils.dispatchGlobalError(err, false, dispatch, 'PARENT_BUSINESS_MAPPED_UNIT_FAILURE');
        })
    return request;
};

export const getChildParentMappedData = (params) => async (dispatch) => {
    dispatch({ type: "CHILD_PARENT_MAPPPED_DATA_REQUEST" });
    const timer = await apiSlowFunction(dispatch,  'Parent & Child Module Mapped Data')
    let request = await Api.childParentMappedId(params)
        .then((res) => {
            clearTimeout(timer);
            dispatch({
                type: "CHILD_PARENT_MAPPPED_DATA_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(timer);
            utils.dispatchGlobalError(err, false, dispatch, 'CHILD_PARENT_MAPPPED_DATA_FAILURE');
        });
    return request;
};
// Add New Child Module
export const addNewParentModule = (selections) => async (dispatch) => {
    dispatch({ type: "NEW_MODULE_REQUEST" });
    const timer = await apiSlowFunction(dispatch,  'Create New Child Module',)
    let request = await Api.addNewParentModule(selections)
        .then((res) => {
            clearTimeout(timer);
            dispatch({
                type: "NEW_MODULE__SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(timer);
            utils.dispatchGlobalError(err, false, dispatch, 'NEW_MODULE_FAILURE');
            return Promise.reject(err);
        });
    return request;
};

export const updateParentModule = (selections) => async (dispatch) => {
    dispatch({ type: "UPDATE_SELECTED_VIEW_REQUEST" });
    const timer = await apiSlowFunction(dispatch,  'update Selected Dashboard View API',)
    let request = await Api.updateParentModule(selections)
        .then((res) => {
            clearTimeout(timer);
            dispatch({
                type: "UPDATE_SELECTED_VIEW_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(timer);
            utils.dispatchGlobalError(err, false, dispatch, 'UPDATE_SELECTED_VIEW_FAILURE');
        });
    return request;
};

// Add New Child Module
export const addNewChildModule = (selections) => async (dispatch) => {
    dispatch({ type: "NEW_MODULE_REQUEST" });
    const timer = await apiSlowFunction(dispatch,  'Create New Child Module',)
    let request = await Api.addNewChildModule(selections)
        .then((res) => {
            clearTimeout(timer);
            dispatch({
                type: "NEW_MODULE__SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(timer);
            utils.dispatchGlobalError(err, false, dispatch, 'NEW_MODULE_FAILURE');
            return Promise.reject(err);
        });
    return request;
};


export const updateChildModule = (selections) => async (dispatch) => {
    dispatch({ type: "UPDATE_SELECTED_VIEW_REQUEST" });
    const timer = await apiSlowFunction(dispatch,  'update Selected Dashboard View API',)
    let request = await Api.updateChildModule(selections)
        .then((res) => {
            clearTimeout(timer);
            dispatch({
                type: "UPDATE_SELECTED_VIEW_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(timer);
            utils.dispatchGlobalError(err, false, dispatch, 'UPDATE_SELECTED_VIEW_FAILURE');
        });
    return request;
};


//Updating the display order of the modules//
export const updateParentModuleOrder = (selections) => async (dispatch) => {
    dispatch({ type: "UPDATE_DISPLAY_ORDER_REQUEST" });
    const timer = await apiSlowFunction(dispatch,  'update Selected Parent Module Display Order',)
    let request = await Api.parentModuleOrder(selections)
        .then((res) => {
            clearTimeout(timer);
            dispatch({
                type: "UPDATE_DISPLAY_ORDER_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(timer);
            utils.dispatchGlobalError(err, false, dispatch, 'UPDATE_DISPLAY_ORDER_FAILURE');
        });
    return request;
};

//Updating the display order of the modules//
export const updateChildModuleOrder = (selections) => async (dispatch) => {
    dispatch({ type: "UPDATE_DISPLAY_ORDER_REQUEST" });
    const timer = await apiSlowFunction(dispatch,  'update Selected Child Module Display Order',)
    let request = await Api.childModuleOrder(selections)
       .then((res) => {
            clearTimeout(timer);
            dispatch({
                type: "UPDATE_DISPLAY_ORDER_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(timer);
            utils.dispatchGlobalError(err, false, dispatch, 'UPDATE_DISPLAY_ORDER_FAILURE');
        });
    return request;
};


export const deActivateParentModule = (params) => async (dispatch) => {
    dispatch({ type: "DELETE_CC_DATA_REQUEST" });
    let request = await Api.inActivateParentModule(params)
        .then((res) => {
            dispatch({
                type: "DELETE_CC_DATA_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        })
        .catch((err) => {
             utils.dispatchGlobalError(err, false, dispatch, 'DELETE_CC_DATA_FAILURE');
        });
    return request;
};

export const deleteParentModule =  (params) => async (dispatch) => {
    dispatch({ type: "DELETE_CC_DATA_REQUEST" });
    let request = await Api.deleteParentModule(params)
        .then((res) => {
            dispatch({
                type: "DELETE_CC_DATA_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        })
        .catch((err) => {
             utils.dispatchGlobalError(err, false, dispatch, 'DELETE_CC_DATA_FAILURE');
        });
    return request;
};

export const deleteChildModule =  (params) => async (dispatch) => {
    dispatch({ type: "DELETE_CC_DATA_REQUEST" });
    let request = await Api.deleteChildModule(params)
        .then((res) => {
            dispatch({
                type: "DELETE_CC_DATA_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        })
        .catch((err) => {
             utils.dispatchGlobalError(err, false, dispatch, 'DELETE_CC_DATA_FAILURE');
        });
    return request;
};

export const updateModuleViewStatus = (selections) => async (dispatch) => {
    dispatch({ type: "UPDATE_SELECTED_VIEW_REQUEST" });
    const timer = await apiSlowFunction(dispatch,  'update Selected Dashboard View API',)
    let request = await apiInstance.post(`/api/updateModuleViewStatus`, selections)
        .then((res) => {
            clearTimeout(timer);
            dispatch({
                type: "UPDATE_SELECTED_VIEW_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(timer);
            utils.dispatchGlobalError(err, false, dispatch, 'UPDATE_SELECTED_VIEW_FAILURE');
        });
    return request;
};

export const insertNewPage = (selections) => async (dispatch) => {
    dispatch({ type: "INSERT_NEW_VIEW_DATA_REQUEST" });
    const timer = await apiSlowFunction(dispatch,  'Create New Dashboards API')
    let request = await apiInstance.post(`/api/addNewDashboard`, selections)
        .then((res) => {
            clearTimeout(timer);
            dispatch({
                type: "INSERT_NEW_VIEW_DATA_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(timer);
            utils.dispatchGlobalError(err, false, dispatch, 'INSERT_NEW_VIEW_DATA_FAILURE');
            return Promise.reject(err);
        });
    return request;
};

export const insertNewSubReport = (selections) => async (dispatch) => {
    dispatch({ type: "INSERT_NEW_GROUPED_REQUEST" });
    const timer = await apiSlowFunction(dispatch,  'Create New Dashboards API')
    let request = await apiInstance.post(`/api/addGroupedReports`, selections)
        .then((res) => {
            clearTimeout(timer);
            dispatch({
                type: "INSERT_NEW_GROUPED_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(timer);
            utils.dispatchGlobalError(err, false, dispatch, 'INSERT_NEW_GROUPED_FAILURE');
            return Promise.reject(err);
        });
    return request;
};

export const getSelectedSubReportDetails = (selections) => async (dispatch) => {
    dispatch({ type: "GET_SUB_REPORT_DETAILS_REQUEST" });
    const timer = await apiSlowFunction(dispatch,  'update Selected Dashboard View API',)
    let request = await Api.getSelectedSubReportDetails(selections)
        .then((res) => {
            clearTimeout(timer);
            dispatch({
                type: "GET_SUB_REPORT_DETAILS_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(timer);
            utils.dispatchGlobalError(err, false, dispatch, 'GET_SUB_REPORT_DETAILS_FAILURE');
        });
    return request;
};

export const updateSelectedSubReport = (selections) => async (dispatch) => {
    dispatch({ type: "UPDATE_SELECTED_VIEW_REQUEST" });
    const timer = await apiSlowFunction(dispatch,  'update Selected Dashboard View API',)
    let request = await apiInstance.post(`/api/updateSelectedSubReport`, selections)
        .then((res) => {
            clearTimeout(timer);
            dispatch({
                type: "UPDATE_SELECTED_VIEW_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(timer);
            utils.dispatchGlobalError(err, false, dispatch, 'UPDATE_SELECTED_VIEW_FAILURE');
        });
    return request;
};


//Updating the display order of the Embedded Report in Module & Region Specific//
export const updateReportOrder = (selections) => async (dispatch) => {
    dispatch({ type: "UPDATE_DISPLAY_ORDER_REQUEST" });
    const timer = await apiSlowFunction(dispatch,  'update Selected Dashboard View API',)
    let request = await apiInstance.put(`/api/updateReportOrder`, selections)
        .then((res) => {
            clearTimeout(timer);
            dispatch({
                type: "UPDATE_DISPLAY_ORDER_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(timer);
            utils.dispatchGlobalError(err, false, dispatch, 'UPDATE_DISPLAY_ORDER_FAILURE');
        });
    return request;
};

//Updating the display order of the Embedded Report in Module & Region Specific//
export const updateSubReportOrder = (selections) => async (dispatch) => {
    dispatch({ type: "UPDATE_DISPLAY_ORDER_REQUEST" });
    const timer = await apiSlowFunction(dispatch,  'update Selected Dashboard View API',)
    let request = await apiInstance.put(`/api/updateSubReportOrder`, selections)
        .then((res) => {
            clearTimeout(timer);
            dispatch({
                type: "UPDATE_DISPLAY_ORDER_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(timer);
            utils.dispatchGlobalError(err, false, dispatch, 'UPDATE_DISPLAY_ORDER_FAILURE');
        });
    return request;
};

export const resetDashboardDetails = () => {
    return {
        type: "RESET_DASHBOARD_DETAILS",
        payload: [
            { viewName: "", viewDescription: "", categoryName: "", viewId: "" },
        ],
    };
};

/*update the Report bookmark state*/
export const updateCustomizedHtmlTemp = (selections) => async (
    dispatch
) => {
    dispatch({
        type: "UPDATE_CUSTOMIZED_HTML_TEMP",
        payload: selections,
    });
    return true;
};

export const updateJsonForView = (selections, callback) => async (dispatch) => {
    dispatch({ type: "UPDATE_JSON_FOR_VIEW_REQUEST" });
    const timer = await apiSlowFunction(dispatch,  'Update HTMLTemp for embed report API')
    let request = await apiInstance.post(`/api/updateHtmlTempForView`, selections)
        .then((res) => {
            clearTimeout(timer);
            dispatch({
                type: "UPDATE_JSON_FOR_VIEW_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            callback(res);
        })
        .catch((err) => {
            clearTimeout(timer);
            utils.dispatchGlobalError(err, false, dispatch, 'UPDATE_JSON_FOR_VIEW_FAILURE');
        });
    return request;
};

export const getDashboardStatus = (params) => async (dispatch) => {
    dispatch({ type: "GET_DASHBOARD_STATUS_REQUEST" });
    const timer = await apiSlowFunction(dispatch,  'Admin Dashboard Status API',)
    let request = await Api.getDashboardStatus(params)
        .then((res) => {
            clearTimeout(timer);
            dispatch({
                type: "GET_DASHBOARD_STATUS_SUCCESS",
                payload: res,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(timer);
            utils.dispatchGlobalError(err, false, dispatch, 'GET_DASHBOARD_STATUS_FAILURE');
        });
    return request;
};

export const updateSelectedView = (selections) => async (dispatch) => {
    dispatch({ type: "UPDATE_SELECTED_VIEW_REQUEST" });
    const timer = await apiSlowFunction(dispatch,  'update Selected Dashboard View API',)
    let request = await apiInstance.post(`/api/updateSelectedView`, selections)
        .then((res) => {
            clearTimeout(timer);
            dispatch({
                type: "UPDATE_SELECTED_VIEW_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(timer);
            utils.dispatchGlobalError(err, false, dispatch, 'UPDATE_SELECTED_VIEW_FAILURE');
        });
    return request;
};

export const deleteModuleReport = (params) => async (dispatch) => {
    dispatch({ type: "DELETE_CC_DATA_REQUEST" });
    let request = await Api.deleteReport(params)
        .then((res) => {
            dispatch({
                type: "DELETE_CC_DATA_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        })
        .catch((err) => {
             utils.dispatchGlobalError(err, false, dispatch, 'DELETE_CC_DATA_FAILURE');
        });
    return request;
};

export const deleteSubReport =  (params) => async (dispatch) => {
    dispatch({ type: "DELETE_CC_DATA_REQUEST" });
    let request = await Api.deleteSubReport(params)
        .then((res) => {
            dispatch({
                type: "DELETE_CC_DATA_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        })
        .catch((err) => {
             utils.dispatchGlobalError(err, false, dispatch, 'DELETE_CC_DATA_FAILURE');
        });
    return request;
};

export const trackUserEvent = (selections) => async (dispatch) => {
    dispatch({ type: "SET_APPLICATION_USAGE_DATA" });
    let request = await apiInstance.post(`/api/users`, selections)
    return request;
};

export const downloadApplicationUsageData = (params) => async (dispatch) => {
    dispatch({ type: "DOWALOAD_APPLICATION_USAGE_DATA" });
    const timer = await apiSlowFunction(dispatch,  `Download Usage Data API`)
    let request = await apiInstance.post(`/api/exportData?userGroup=${params.adgroup}`)
        .then((res) => {
            clearTimeout(timer);
            dispatch({
                type: "DOWALOAD_APPLICATION_USAGE_DATA_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(timer);
            utils.dispatchGlobalError(err, false, dispatch, 'DOWALOAD_APPLICATION_USAGE_DATA_FAILURE');
        });
    return request;
};

export const saveViewBuilder = (params) => async (
    dispatch
) => {
    dispatch({
        type: "SAVE_VIEW_BUILDER_SUCCESS",
        payload: params,
    });
    return true;
};

/*update the View Builder JSON*/
export const capturePlaybooksData = (selections) => async (
    dispatch
) => {
    dispatch({
        type: "CAPTURE_PLAYBOOKS_DATA",
        payload: selections,
    });
    return true;
};

/*update the View Builder JSON*/
export const updateBookmarkViews = (selections) => async (
    dispatch
) => {
    dispatch({
        type: "UPDATE_BOOKMARK_VIEW",
        payload: selections,
    });
    return true;
};

/*update the View Builder JSON*/
export const updatePlaybooksContentPages = (selections) => async (
    dispatch
) => {
    dispatch({
        type: "UPDATE_PLAYBOOKS_CONTENT_PAGE",
        payload: selections,
    });
    return true;
};

export const fetchBookmarks = (params) => async (dispatch) => {
    dispatch({ type: "FETCH_BOOKMARK_REQUEST" });
    const timer = await apiSlowFunction(dispatch, 'Bookmark Data API' )
    let request = await apiInstance.post(`/api/bookmarksList`, params)
        .then((res) => {
            clearTimeout(timer);
            dispatch({
                type: "FETCH_BOOKMARK_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(timer);
            utils.dispatchGlobalError(err, false, dispatch, 'FETCH_BOOKMARK_FAILURE');
        });
    return request;
};

export const captureNewBookmark = (selections) => async (dispatch) => {
    dispatch({ type: "CREATE_NEW_BOOKMARK_REQUEST" });
    const timer = await apiSlowFunction(dispatch,  'Capture New Bookmark API', )
    let request = await apiInstance.post(`/api/bookmarks`, selections)
        .then((res) => {
            clearTimeout(timer);
            dispatch({
                type: "CREATE_NEW_BOOKMARK_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(timer);
            utils.dispatchGlobalError(err, false, dispatch, 'CREATE_NEW_BOOKMARK_FAILURE');
        });
    return request;
};

export const editBookmark = (selections) => async (dispatch) => {
    dispatch({ type: "EDIT_BOOKMARK_REQUEST" });
    const timer = await apiSlowFunction(dispatch,  'Update Bookmark API', )
    let request = await apiInstance.get(`/api/editBookmarks`, selections)
        .then((res) => {
            clearTimeout(timer);
            dispatch({
                type: "EDIT_BOOKMARK_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(timer);
            utils.dispatchGlobalError(err, false, dispatch, 'EDIT_BOOKMARK_FAILURE');
        });
    return request;
};

export const updateBookmark = (selections) => async (dispatch) => {
    dispatch({ type: "UPDATE_BOOKMARK_REQUEST" });
    let request = await apiInstance.put(`/api/editBookmarks`, selections)
        .then((res) => {
            // clearTimeout(timer);
            dispatch({
                type: "UPDATE_BOOKMARK_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        })
        .catch((err) => {
            // clearTimeout(timer);
            utils.dispatchGlobalError(err, false, dispatch, 'UPDATE_BOOKMARK_FAILURE');
        });
    return request;
};

export const deleteBookmark = (params) => async (dispatch) => {
    dispatch({ type: "DELETE_CC_DATA_REQUEST" });
    let request = await apiInstance.delete(`/api/deleteBookmarks?bookmarkID=${params.bookmarkID}`)
        .then((res) => {
            dispatch({
                type: "DELETE_CC_DATA_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        })
        .catch((err) => {
            // clearTimeout(timer);
            utils.dispatchGlobalError(err, false, dispatch, 'DELETE_CC_DATA_FAILURE');
        });
    return request;
};

// get request to fetchShareablePlayBookLinks
export const fetchShareablePlayBookLinks = (params) => async (dispatch) => {
    dispatch({ type: "FETCH_SHAREABLE_PLAYBOOKS_LINK_REQUEST" });
    const timer = await apiSlowFunction(dispatch,   'fetch Shareable PlayBook Links API')
    let request = await Api.getShareLinkData(params)
        .then((res) => {
            clearTimeout(timer);
            dispatch({
                type: "FETCH_SHAREABLE_PLAYBOOKS_LINK_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(timer);
            utils.dispatchGlobalError(err, false, dispatch, 'FETCH_SHAREABLE_PLAYBOOKS_LINK_FAILURE');
        });
    return request;
};

export const generateShareablePlayBooksLinks = (selections) => async (dispatch) => {
    dispatch({ type: "GENERATE_SHAREABLE_PLAYBOOKS_LINK_REQUEST" });
    const timer = await apiSlowFunction(dispatch,   'Generate Shareable PlayBooks Link API')
    let request = await apiInstance.post(`/api/postShareLink`, selections)
        .then((res) => {
            clearTimeout(timer);
            dispatch({
                type: "GENERATE_SHAREABLE_PLAYBOOKS_LINK_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(timer);
            utils.dispatchGlobalError(err, false, dispatch, 'GENERATE_SHAREABLE_PLAYBOOKS_LINK_FAILURE');
        });
    return request;
};

export const fetchFaqData = (params) => async (dispatch) => {
    dispatch({ type: "FETCH_FAQ_DATA_LINK_REQUEST" });
    const timer = await apiSlowFunction(dispatch,  `Fetch Faq Content API`,)
    let request = await Api.getFaqlink(params)
        .then((res) => {
            clearTimeout(timer);
            dispatch({
                type: "FETCH_FAQ_DATA_REQUEST_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(timer);
            dispatch({
                type: "FETCH_FAQ_DATA_REQUEST_FAILURE",
                payload: err,
            });
            return Promise.reject(err);
        });
    return request;
};


export const getErrorMessages = () => async (dispatch) => {
    dispatch({ type: "GET_ERROR_MESSAGES_REQUEST" });
    const timer = await apiSlowFunction(dispatch,  `Fetch Error Message API`,)
    let request = await Api.getErrorMessage().then((res) => {
        clearTimeout(timer);
        dispatch({ type: "GET_ERROR_MESSAGES_SUCCESS", payload: res.data.data, });
        dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
        return Promise.resolve(res);
    })
        .catch((err) => {
            clearTimeout(timer);
            dispatch({ type: "GET_ERROR_MESSAGES_FAILURE", payload: err });
            return Promise.reject(err);
        });
    return request;
};

export const updateErrorMessages = (params) => async (dispatch) => {
    dispatch({ type: "UPDATE_ERROR_MESSAGES_REQUEST" });
    const timer = await apiSlowFunction(dispatch,  `Update Error Message API`,)
    let request = await Api.updateErrorMessages(params)
        .then((res) => {
            clearTimeout(timer);
            dispatch({
                type: "UPDATE_ERROR_MESSAGES_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } });
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(timer);
            utils.dispatchGlobalError(err, false, dispatch, 'UPDATE_ERROR_MESSAGES_FAILURE');
        });
    return request;
}

export const updateShareReport = (selections) => async (dispatch) => {
    dispatch({
        type: "FETCH_SHAREABLE_PLAYBOOKS_LINK_REQUEST",
        payload: selections,
    });
    return true;
};



//Notification API Actions
export const fetchAlertField = () => async (dispatch) => {
    dispatch({ type: "FETCH_ALERT_FIELD_REQUEST" });
    const timer = await apiSlowFunction(dispatch,  `Fetch Alert Input fields API`,)
    let request = await Api.alertField().then((res) => {
        clearTimeout(timer);
        dispatch({ type: "FETCH_ALERT_FIELD_SUCCESS", payload: res.data.data });
        dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
        return Promise.resolve(res);
    })
    .catch((err) => {
        clearTimeout(timer);
        dispatch({ type: "FETCH_ALERT_FIELD_FAILURE", payload: err });
        return Promise.reject(err);
    });
    return request;
};

export const updateAlertFieldData = (params) => async (dispatch) => {
    dispatch({ type: "UPDATE_ALERT_FIELD_DATA", payload: params });
    return true;
};

export const OperationalAlertsTypes = () => async (dispatch) => {
    dispatch({ type: "OPERATIONAL_ALERTS_TYPES_REQUEST" });
    const myTimeout = await apiSlowFunction(dispatch,  `Fetch Alert Data Type API`)
    let request = await Api.operationalAlertsType().then((res) => {
        clearTimeout(myTimeout);
        dispatch({ type: "OPERATIONAL_ALERTS_TYPES_SUCCESS", payload: res.data.data });
        dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
        return Promise.resolve(res);
    })
        .catch((err) => {
            clearTimeout(myTimeout);
            dispatch({ type: "OPERATIONAL_ALERTS_TYPES_FAILURE", payload: err });
            return Promise.reject(err);
        });
    return request;
};

export const getOperationalAlerts = () => async (dispatch) => {
    dispatch({ type: "GET_OPERATIONAL_ALERTS_REQUEST" });
    const myTimeout = await apiSlowFunction(dispatch,  `Fetch Alert List API`,)
    let request = await Api.operationAlert().then((res) => {
        clearTimeout(myTimeout);
        dispatch({ type: "GET_OPERATIONAL_ALERTS_SUCCESS", payload: res.data.data, });
        dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
        return Promise.resolve(res);
    })
        .catch((err) => {
            clearTimeout(myTimeout);
            dispatch({ type: "GET_OPERATIONAL_ALERTS_FAILURE", payload: err });
            return Promise.reject(err);
        });
    return request;
};


export const fetchRegionalMappingData = (params) => async (dispatch) => {
    dispatch({ type: "FETCH_REGIONAL_MAPPING_DATA_REQUEST" });
    let myTimeout = await apiSlowFunction(dispatch,  `Regional Mapping Data API`);
    let request = await Api.regionalMappingData(params)
        .then((res) => {
            clearTimeout(myTimeout);
            dispatch({ type: "FETCH_REGIONAL_MAPPING_DATA_SUCCESS", payload: res.data.data, });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(myTimeout);
            dispatch({ type: "FETCH_REGIONAL_MAPPING_DATA_FAILURE", payload: err });
            return Promise.reject(err);
        });
    return request;
};

export const createOperationalAlerts = (params) => async (dispatch) => {
    dispatch({ type: "CREATE_OPERATIONAL_ALERTS_REQUEST" });
    const myTimeout = await apiSlowFunction(dispatch,  `Configure Alert API`);
    let request = await Api.createOperationalAlerts(params)
        .then((res) => {
            clearTimeout(myTimeout);
            dispatch({
                type: "CREATE_OPERATIONAL_ALERTS_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } });
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(myTimeout);
            utils.dispatchGlobalError(err, false, dispatch, 'CREATE_OPERATIONAL_ALERTS_FAILURE');
        });
    return request;
};

export const updateOperationalAlerts = (params) => async (dispatch) => {
    dispatch({ type: "UPDATE_OPERATIONAL_ALERTS_REQUEST" });
    const myTimeout = await apiSlowFunction(dispatch,  `Update Alert API`);
    let request = await Api.updateOperationalAlerts(params)
        .then((res) => {
            clearTimeout(myTimeout);
            dispatch({
                type: "UPDATE_OPERATIONAL_ALERTS_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } });
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(myTimeout);
            utils.dispatchGlobalError(err, false, dispatch, 'UPDATE_OPERATIONAL_ALERTS_FAILURE');
        });
    return request;
};

export const OperationalAlertsAppliedFilter = () => async (dispatch) => {
    dispatch({ type: "OPERATIONAL_ALERTS_APPLIED_FILTER_REQUEST" });
    return true;
};

export const updateCreatedOperationalAlerts = () => async (dispatch) => {
    dispatch({ type: "UPDATE_CREATED_OPERATIONAL_ALERTS_REQUEST" });
    return true;
};

export const updateOperationalAlertsFilterData = (params) => async (dispatch) => {
    dispatch({ type: "UPDATE_OPERATIONAL_ALERTS_FILTER_DATA_REQUEST", payload: params });
    return true;
};

export const fetchOptAlertTargetUser = (params) => async (dispatch) => {
    dispatch({ type: "FETCH_OPT_ALERTS_TARGET_USER_REQUEST" });
    let myTimeout = await apiSlowFunction(dispatch,  `User Mapping API`);
    let request = await Api.targetUser(params)
        .then((res) => {
            clearTimeout(myTimeout);
            dispatch({ type: "FETCH_OPT_ALERTS_TARGET_USER_SUCCESS", payload: res.data.data, });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(myTimeout);
            dispatch({ type: "FETCH_OPT_ALERTS_TARGET_USER_FAILURE", payload: err });
            return Promise.reject(err);
        });
    return request;
};

export const fetchOptAlertTargetUserCountry = (params) => async (dispatch) => {
    dispatch({ type: "FETCH_OPT_ALERTS_TARGET_USER_COUNTRY_REQUEST" });
    let myTimeout = await apiSlowFunction(dispatch,  'User Country API');
    let request = await Api.userCountry(params)
        .then((res) => {
            clearTimeout(myTimeout);
            dispatch({
                type: "FETCH_OPT_ALERTS_TARGET_USER_COUNTRY_SUCCESS",
                payload: res.data.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } });
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(myTimeout);
            utils.dispatchGlobalError(err, false, dispatch, 'FETCH_OPT_ALERTS_TARGET_USER_COUNTRY_FAILURE');
        });
    return request;
};

export const fetchOptAlertTargetUserTeam = (params) => async (dispatch) => {
    dispatch({ type: "FETCH_OPT_ALERTS_TARGET_USER_TEAM_REQUEST" });
    let myTimeout = await apiSlowFunction(dispatch,  'User Team API');
    let request = await Api.userTeam(params)
        .then((res) => {
            clearTimeout(myTimeout);
            dispatch({
                type: "FETCH_OPT_ALERTS_TARGET_USER_TEAM_SUCCESS",
                payload: res.data.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } });
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(myTimeout);
            utils.dispatchGlobalError(err, false, dispatch, 'FETCH_OPT_ALERTS_TARGET_USER_TEAM_FAILURE');
        });
    return request;
};

export const publishOperationalAlerts = (params) => async (dispatch) => {
    dispatch({ type: "PUBLISH_OPERATIONAL_ALERTS_REQUEST" });
    let myTimeout = await apiSlowFunction(dispatch,  'Publish Alert API');
    let request = await Api.publishAlerts(params)
        .then((res) => {
            clearTimeout(myTimeout);
            dispatch({
                type: "PUBLISH_OPERATIONAL_ALERTS_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } });
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(myTimeout);
            utils.dispatchGlobalError(err, false, dispatch, 'PUBLISH_OPERATIONAL_ALERTS_FAILURE');
        });
    return request;
};

export const updatePublishOperationalAlerts = () => async (dispatch) => {
    dispatch({ type: "UPDATE_PUBLISH_OPERATIONAL_ALERTS_REQUEST" });
    return true;
};

export const fetchUserSubscriptionData = (params) => async (dispatch) => {
    dispatch({ type: "FETCH_USER_SUBSCRIPTION_DATA_REQUEST" });
    let myTimeout = await apiSlowFunction(dispatch,  'Fetch User Subscriptions Data API');
    let request = await Api.userSubscribeData(params)
        .then((res) => {
            clearTimeout(myTimeout);
            dispatch({
                type: "FETCH_USER_SUBSCRIPTION_DATA_SUCCESS",
                payload: res.data.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } });
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(myTimeout);
            utils.dispatchGlobalError(err, false, dispatch, 'FETCH_USER_SUBSCRIPTION_DATA_FAILURE');
        });
    return request;
};

export const userSubscription = (params) => async (dispatch) => {
    dispatch({ type: "USER_SUBSCRIPTION_REQUEST" });
    let myTimeout = await apiSlowFunction(dispatch,  'User Subscriptions API');
    let request = await Api.userSubscription(params)
        .then((res) => {
            clearTimeout(myTimeout);
            dispatch({
                type: "USER_SUBSCRIPTION_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } });
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(myTimeout);
            utils.dispatchGlobalError(err, false, dispatch, 'USER_SUBSCRIPTION_FAILURE');
        });
    return request;
};

export const fetchNotification = (params) => async (dispatch) => {
    dispatch({ type: "FETCH_NOTIFICATION_REQUEST" });
    let myTimeout = await apiSlowFunction(dispatch,  'Notification API');
    let request = await Api.notification(params)
        .then((res) => {
            clearTimeout(myTimeout);
            dispatch({
                type: "FETCH_NOTIFICATION_SUCCESS",
                payload: res.data.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } });
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(myTimeout);
            utils.dispatchGlobalError(err, false, dispatch, 'FETCH_NOTIFICATION_FAILURE');
        });
    return request;
};

export const updateNotificationRegion = (params) => async (dispatch) => {
    dispatch({ type: "UPDATE_NOTIFICATION_REGION_REQUEST", payload: params  });
    return true;
};

export const clearNotifications = (params) => async (dispatch) => {
    dispatch({ type: "CLEAR_NOTIFICATION_REQUEST" });
    let myTimeout = await apiSlowFunction(dispatch,  'Remove Notification API');
    let request = await Api.clearNotifications(params)
        .then((res) => {
            clearTimeout(myTimeout);
            dispatch({
                type: "CLEAR_NOTIFICATION_SUCCESS",
                payload: res.data.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } });
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(myTimeout);
            utils.dispatchGlobalError(err, false, dispatch, 'CLEAR_NOTIFICATION_FAILURE');
        });
    return request;
};

export const updateEditorData = (params) => async (dispatch) => {
    dispatch({ type: "UPDATE_EDITOR_DATA", payload: params });
    return true;
};

export const editEditorData = (params) => async (dispatch) => {
    dispatch({ type: "EDIT_EDITOR_DATA", payload: params });
    return true;
};

export const releaseNotesFieldData = () => async (dispatch) => {
    dispatch({ type: "RELEASE_NOTES_DATA_REQUEST", });
    let myTimeout = await apiSlowFunction(dispatch,  'Create New Release Field API');
    let request = await Api.releaseFields()
        .then((res) => {
            clearTimeout(myTimeout);
            dispatch({
                type: "RELEASE_NOTES_DATA_SUCCESS",
                payload: res.data.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } });
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(myTimeout);
            utils.dispatchGlobalError(err, false, dispatch, 'RELEASE_NOTES_DATA_FAILURE');
        });
    return request;
};

export const createNewReleaseVersion = (params) => async (dispatch) => {
    dispatch({ type: "CREATE_NEW_RELEASE_REQUEST" });
    let myTimeout = await apiSlowFunction(dispatch,  'Create New Release API');
    let request = await Api.createRelease(params)
        .then((res) => {
            clearTimeout(myTimeout);
            dispatch({
                type: "CREATE_NEW_RELEASE_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } });
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(myTimeout);
            utils.dispatchGlobalError(err, false, dispatch, 'CREATE_NEW_RELEASE_FAILURE');
        });
    return request;
};

export const releaseVersionData = (params) => async (dispatch) => {
    dispatch({ type: "RELEASE_LIST_DATA_REQUEST" });
    let myTimeout = await apiSlowFunction(dispatch,  'Release Version List API');
    let request = await Api.releaseVersionList(params)
        .then((res) => {
            clearTimeout(myTimeout);
            dispatch({
            type: "RELEASE_LIST_DATA_SUCCESS",
                payload: res.data.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } });
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(myTimeout);
            utils.dispatchGlobalError(err, false, dispatch, 'RELEASE_LIST_DATA_FAILURE');
        });
    return request;
};

export const createNewReleaseTitle = (params) => async (dispatch) => {
    dispatch({ type: "CREATE_NEW_RELEASE_TITLE_REQUEST" });
    let myTimeout = await apiSlowFunction(dispatch,  'Create New Release Name API');
    let request = await Api.createNewReleaseTitle(params)
        .then((res) => {
            clearTimeout(myTimeout);
            dispatch({
                type: "CREATE_NEW_RELEASE_TITLE_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } });
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(myTimeout);
            utils.dispatchGlobalError(err, false, dispatch, 'CREATE_NEW_RELEASE_TITLE_FAILURE');
        });
    return request;
};

export const fetchReleaseDataList = (params) => async (dispatch) => {
    dispatch({ type: "RELEASE_NOTES_DATA_LIST_REQUEST" });
    let myTimeout = await apiSlowFunction(dispatch,  'Release Data List API');
    let request = await Api.releaseDataList(params)
        .then((res) => {
            clearTimeout(myTimeout);
            dispatch({
                type: "RELEASE_NOTES_DATA_LIST_SUCCESS",
                payload: res.data.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } });
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(myTimeout);
            utils.dispatchGlobalError(err, false, dispatch, 'RELEASE_NOTES_DATA_LIST_FAILURE');
        });
    return request;
};

export const fetchReleaseTitleData = (params) => async (dispatch) => {
    dispatch({ type: "EDIT_RELEASE_DATA_REQUEST" });
    let myTimeout = await apiSlowFunction(dispatch,  'Fetch Release Name API');
    let request = await Api.getReleaseTitleData(params)
        .then((res) => {
            clearTimeout(myTimeout);
            dispatch({
                type: "EDIT_RELEASE_DATA_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } });
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(myTimeout);
            utils.dispatchGlobalError(err, false, dispatch, 'EDIT_RELEASE_DATA_FAILURE');
        });
    return request;
};

export const publishReleaseNotes = (params) => async (dispatch) => {
    dispatch({ type: "PUBLISH_RELEASE_NOTES_REQUEST" });
    let myTimeout = await apiSlowFunction(dispatch,  'Publish New Release API');
    let request = await Api.publishRelease(params)
        .then((res) => {
            clearTimeout(myTimeout);
            dispatch({
                type: "PUBLISH_RELEASE_NOTES_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } });
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(myTimeout);
            utils.dispatchGlobalError(err, false, dispatch, 'PUBLISH_RELEASE_NOTES_FAILURE');
        });
    return request;
};



export const createNewsletter = (params) => async (dispatch) => {
    dispatch({ type: "CREATE_NEWSLETTER_REQUEST" });
    let myTimeout = await apiSlowFunction(dispatch,  'Create Newsletter API');
    let request = await Api.createNewsletter(params)
        .then((res) => {
            clearTimeout(myTimeout);
            dispatch({
                type: "CREATE_NEWSLETTER_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } });
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(myTimeout);
            utils.dispatchGlobalError(err, false, dispatch, 'CREATE_NEWSLETTER_FAILURE');
        });
    return request;
};

export const fetchNewsletterQuarter = (params) => async (dispatch) => {
    dispatch({ type: 'NEWSLETTER_QUARTER_REQUEST' });
    let myTimeout = await apiSlowFunction(dispatch,  'Newsletter Months API');
    let request = await Api.newsletterMonth(params)
        .then((res) => {
            clearTimeout(myTimeout);
            dispatch({
                type: 'NEWSLETTER_QUARTER_SUCCESS',
                payload: res.data.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } });
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(myTimeout);
            utils.dispatchGlobalError(err, false, dispatch, 'NEWSLETTER_QUARTER_FAILURE');
        });
    return request;
};

export const getNewsletterData = (params) => async (dispatch) => {
    dispatch({ type: "NEWSLETTER_DATA_LIST_REQUEST" });
    let myTimeout = await apiSlowFunction(dispatch,  'Newsletter Months API');
    let request = await Api.getNewsletterData(params)
        .then((res) => {
            clearTimeout(myTimeout);
            dispatch({
                type: "NEWSLETTER_DATA_LIST_SUCCESS",
                payload: res.data.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } });
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(myTimeout);
            utils.dispatchGlobalError(err, false, dispatch, 'NEWSLETTER_DATA_LIST_FAILURE');
        });
    return request;
};

export const updateNewsLetter = (params) => async (dispatch) => {
    dispatch({ type: "UPDATE_NEWSLETTER_REQUEST" });
    let myTimeout = await apiSlowFunction(dispatch,  'Update Newsletter API');
    let request = await Api.updateNewsletter(params)
        .then((res) => {
            clearTimeout(myTimeout);
            dispatch({
                type: "UPDATE_NEWSLETTER_SUCCESS",
                payload: res.data.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } });
            return Promise.resolve(res);
        })
        .catch((err) => {
            clearTimeout(myTimeout);
            utils.dispatchGlobalError(err, false, dispatch, 'UPDATE_NEWSLETTER_FAILURE');
        });
    return request;
};

export const updateEditorModuleFeatures = (params) => async (dispatch) => {
    dispatch({ type: "UPDATE_EDITOR_MODULE_FEATURE_REQUEST" });
    let request = await Api.updateModuleName(params)
        .then((res) => {
            dispatch({
                type: "UPDATE_EDITOR_MODULE_FEATURE_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        })
        .catch((err) => {
            utils.dispatchGlobalError(err, false, dispatch, 'UPDATE_EDITOR_MODULE_FEATURE_FAILURE');
        });
    return request;
};

export const updateReleaseNotesFeature = (params) => async (dispatch) => {
    dispatch({ type: "UPDATE_RELEASE_NOTES_FEATURE_REQUEST" });
    let request = await Api.editSubCategoryName(params)
        .then((res) => {
            dispatch({
                type: "UPDATE_RELEASE_NOTES_FEATURE_SUCCESS",
                payload: res.data,
            });
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        })
        .catch((err) => {
            utils.dispatchGlobalError(err, false, dispatch, 'UPDATE_RELEASE_NOTES_FEATURE_FAILURE');
        });
    return request;
};

export const deleteAlert = (params) => async (dispatch) => {
    let request = await Api.deleteAlert(params)
       .then((res) => {
           dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
           return Promise.resolve(res);
       })
       .catch((err) => {
           utils.dispatchGlobalError(err, false, dispatch, 'GLOBAL_API_ERROR');
       });
   return request;
};

export const deleteEditorModuleFeatures = (params) => async (dispatch) => {
     let request = await Api.deleteModuleName(params)
        .then((res) => {
            dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        })
        .catch((err) => {
            utils.dispatchGlobalError(err, false, dispatch, 'GLOBAL_API_ERROR');
        });
    return request;
};

export const deleteReleaseNotesFeature = (params) => async (dispatch) => {
     let request = await Api.deleteSubCategory(params)
        .then((res) => {
             dispatch({ type: "GLOBAL_SLOW_API_SUCCESS", payload: { trigger: true, type: "ApiSlow" } })
            return Promise.resolve(res);
        })
        .catch((err) => {
            utils.dispatchGlobalError(err, false, dispatch, 'GLOBAL_API_ERROR');
        });
    return request;
};

