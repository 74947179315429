import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./style.js";
import { useHistory } from "react-router-dom";
// import cloneDeep from 'lodash/cloneDeep';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
// import BarLoader from "react-spinners/BarLoader";
import {
    updateActiveBusinessUnit,
    updateModuleViewStatus,
    toastTriggered,
    getRegions,
    deleteParentModule,
    parentBusinessMappedIds
} from "../../redux/actions/index";
import {
    createTheme,
    MuiThemeProvider,
} from "@material-ui/core/styles";
import arrowRight from '../../assets/icons/arrowRight.svg';
import analytics from '../../assets/icons/Self Service Analytics.svg';
import foundationAnalytics from '../../assets/icons/foundationAnalytics.svg';
import resources from '../../assets/icons/resources.svg';
import strategicPricing from '../../assets/icons/Strategic Pricing.svg';
import PPA from '../../assets/icons/Price Pack Architecture.svg';  
import mixm from '../../assets/icons/Mix Management.svg';
import tprom from '../../assets/icons/Trade Promotions.svg';
import cpol from '../../assets/icons/Commercial Policy.svg';  
import additional from '../../assets/icons/Customized Reports.svg';
import CT from '../../assets/icons/control tower.svg';
import dartboards from '../../assets/icons/dartboards.svg';
import { LeftArrowIcon, TrashIcon, RightArrowIcon } from '../../assets/icons/Icons';
import '../businessUnit/style.css';
import toastProperties from "../../assets/data/toastProperty";
import utils from '../../utils/index.js';
import VisibilityIcon from '@material-ui/icons/Visibility';

const BusinessUnit = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    let history = useHistory();
    const { activeBusinessUnit, activeRegion, } = useSelector((state) => state.activeDynamicData);
    const parentMappedIds = useSelector((state) => state.parentBuMapped);

    const container = useRef(null);

    // const [customMessage,  TickIcon setCustomMessage] = useState({});
    const [selectedTab, setSelectedTab] = useState("Active");
    const [moduleNumber, setModuleNumber] = useState(3);

    let imageList = {
        analytics,
        foundationAnalytics,
        resources,
        arrowRight,
        strategicPricing,
        PPA,
        mixm,
        tprom,
        cpol,
        additional,
        CT,
        dartboards
    };

    const theme = createTheme({
        overrides: {
            MuiTooltip: {
                tooltip: {
                    fontSize: "1em",
                    color: "#fff",
                    fontFamily: 'Gilroy-Medium',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px'/* 142.857% */,
                    marginBottom: '0px',
                    padding: '5px 10px',
                    zIndex: 1,
                }
            }
        }
    });

    useEffect(() => {
        if (Array.isArray(activeBusinessUnit) && activeBusinessUnit.length > 0) {
            let data = activeBusinessUnit.filter((el) => el.viewStatus === selectedTab);
            let number = Array.isArray(data) && data.length;
            setModuleNumber(number);
        }
    }, [activeBusinessUnit, selectedTab]);

    const changeUserSelectedTab = (tab) => {
        setSelectedTab(tab);
    };

    const collpaseChildModule = (index) => {
        let activeParentModule = Array.isArray(activeBusinessUnit) && activeBusinessUnit.length > 0 && activeBusinessUnit[index]
        activeParentModule.collapse = !activeParentModule.collapse;
        dispatch(updateActiveBusinessUnit(activeBusinessUnit));
    };


    const trackEvents = (moduleData, type) => {
        utils.userTrackingInfo({
            region: activeRegion,
            actionType: 'Landing Page Admin Activity',
            pageName: 'Dashboard',
            reportName: moduleData.parentModule,
            pageDetails: type,
            attributeName: moduleData.pageName
        });
    }

    const deleteParentModuleMapping = (moduleData) => {
        const parentIds = [];
        const { parentBuMapping } = parentMappedIds;
        let Mappingparams = { region: activeRegion, parentModule: moduleData.parentModule, title: moduleData.title }
        let text = `Are You Sure You Want to Delete ${moduleData.title} Parent Module Parmanently? This Action Can't be UNDONE`;
        if (window.confirm(text)) {
            if (Array.isArray(parentBuMapping) && parentBuMapping.length === 0) {
                dispatch(parentBusinessMappedIds(Mappingparams)).then((res) => {
                    let parentIdData = res.data.data;
                    Array.isArray(parentIdData) && parentIdData.length > 0 && parentIdData.forEach((el) => {
                        parentIds.push(el.parentId)
                    })
                    let params = { parentModule: moduleData.parentModule, title: moduleData.title, parentId: parentIds, region: activeRegion };
                    dispatch(deleteParentModule(params)).then((res) => {
                        trackEvents(moduleData, "Parent Module Parmanently Deleted");
                        toastProperties.success["description"] = res.data.message;
                        dispatch(toastTriggered([toastProperties.success]));
                        dispatch(getRegions());
                        dispatch({ type: "PARENT_BUSINESS_MAPPED_UNIT_REQUEST" });
                    }).catch((err) => {
                        toastProperties.error["description"] = err.message;
                        dispatch(toastTriggered([toastProperties.error]));
                    });
                })
            };
        }
    }

    const activateParentModule = (moduleData) => {
        const parentIds = [];
        const { parentBuMapping } = parentMappedIds;
        let Mappingparams = { region: activeRegion, parentModule: moduleData.parentModule, title: moduleData.title }
        let text = `Are you certain you wish to activate the New Parent Module? Once activated, it will appear on the landing page and be accessible to users in all regions.`;
        if (window.confirm(text)) {
            if (Array.isArray(parentBuMapping) && parentBuMapping.length === 0) {
                dispatch(parentBusinessMappedIds(Mappingparams)).then((res) => {
                    let parentIdData = res.data.data;
                    Array.isArray(parentIdData) && parentIdData.length > 0 && parentIdData.forEach((el) => {
                        parentIds.push(el.parentId)
                    })
                    let params = { parentModule: moduleData.parentModule, title: moduleData.title, parentId: parentIds, region: activeRegion };
                    dispatch(updateModuleViewStatus(params)).then((res) => {
                        trackEvents(moduleData, "Parent Module Activated");
                        toastProperties.success["description"] = res.data.message;
                        dispatch(toastTriggered([toastProperties.success]));
                        dispatch(getRegions());
                        dispatch({ type: "PARENT_BUSINESS_MAPPED_UNIT_REQUEST" });
                    }).catch((err) => {
                        toastProperties.error["description"] = "There is some problem while activating the parent module, please try again after some time";
                        dispatch(toastTriggered([toastProperties.error]));
                    });
                })
            }
        }
    };

    const renderBusinessUnit = () => {
        let data = activeBusinessUnit.filter((el) => el.viewStatus === selectedTab);
        data.sort((function (a, b) { return a.parentOrder - b.parentOrder }))
        if (Array.isArray(data) && data.length > 0) {
            return (
                data.map((units, index) =>
                (
                    <Grid container className={classes.parentModulePosition} key={index} xs={2}>
                        <Grid style={{ background: units.background }} className={classes.parentModule}>
                            {
                                selectedTab !== "Active" ?
                                    <Grid item xs={12} className={classes.rightAlign} >
                                        <Grid className={classes.trashPos} onClick={() => activateParentModule(units)}>
                                            <span className="d-flex" data-toggle="tooltip" data-placement="top" title="Activate Parent Module">  <VisibilityIcon /> </span>
                                        </Grid>
                                        <Grid className={classes.trashPos} onClick={() => deleteParentModuleMapping(units)}>
                                            <span className="d-flex" data-toggle="tooltip" data-placement="top" title="Parmanently Delete Modules">  <TrashIcon /></span>
                                        </Grid>
                                    </Grid>
                                    : null
                            }
                             <Grid container xs={12}>
                                <Grid xs={12} className={classes.logoBox} onClick={() => collpaseChildModule(index)}>
                                    <img className={classes.parentImg} alt="complex" src={imageList[units.image]} />
                                </Grid>
                                <Grid xs={12} className={classes.parentContent}>
                                    <Typography gutterBottom variant="h5" className={classes.ParentTitle}>
                                        {units.title}
                                    </Typography>
                                </Grid>
                             </Grid>
                        </Grid>
                        <Grid container className={classes.childModulePosition} key={index} xs={12}>
                            {
                                !units.collapse ?
                                    units.childModule.length > 0 ?
                                        units.childModule.sort((function (a, b) { return a.childOrder - b.childOrder })) &&
                                        units.childModule.map((unit, childIndex) =>
                                        (
                                            <Grid container key={childIndex} xs={12} className={classes.childModule}>
                                                <Grid className={classes.flexRow}>
                                                    <Grid className={classes.contentBox}>
                                                        <Typography gutterBottom variant="h5" className={classes.Title}>
                                                            {unit.title}
                                                        </Typography>
                                                        {
                                                            unit.description && unit.description.length > 25 ?
                                                                <MuiThemeProvider theme={theme}>
                                                                    <Tooltip title={unit.description}>
                                                                        <Typography gutterBottom variant="subtitle2" className={classes.subtitle}>
                                                                            {unit.description}
                                                                        </Typography>
                                                                    </Tooltip>
                                                                </MuiThemeProvider>
                                                                :
                                                                <Typography gutterBottom variant="subtitle2" className={classes.subtitle}>
                                                                    {unit.description}
                                                                </Typography>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                        )
                                        :
                                        (
                                            <Grid container key={index} xs={12} className={classes.childModule}>
                                                <Grid className={classes.flexRow}>
                                                    <Grid className={classes.contentBox}>
                                                        <Typography gutterBottom variant="subtitle2" className={classes.subtitle}>
                                                            {units.collapseText}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    :
                                    (
                                        <Grid container key={index} xs={12} className={classes.childModule}>
                                            <Grid className={classes.flexRow}>
                                                <Grid className={classes.contentBox}>
                                                    <Typography gutterBottom variant="subtitle2" className={classes.subtitle}>
                                                        {units.collapseText}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )

                            }
                        </Grid>
                    </Grid>
                )
                )

            )
        }
        else if (Array.isArray(data) && data.length === 0) {
            return (
                <Grid container xs={10} className={classes.previewModulePosition}>
                    <Typography gutterBottom variant="h3" >
                        {`There is no module under ${selectedTab} state in ${activeRegion} region`}
                    </Typography>
                </Grid>
            )
        }
    };

    const redirectBack = () => {
        history.push({
            pathname: '/landingPage/edit-home-screen',
        });
    };

    

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Grid container item xs={12} className={classes.flexContainer}>
                    <Grid container item xs={12} className={classes.flexRow} >
                         <Grid item xs={12} className={classes.flexRow}>
                            <Grid item xs={12} className={classes.flexRow}>
                                <Grid item xs={2} className="addModule" onClick={() => redirectBack()}>
                                         <LeftArrowIcon />
                                    Preview
                                </Grid>
                                <Grid item xs={4} className="d-flex pl-3 dashboard-button-row landingState" >
                                    <button className={`btn btn-plain prettier widthState33 ${selectedTab === "Active" ? "active" : null}`} onClick={(e) => { changeUserSelectedTab(e.target.innerText) }}>Active</button>
                                    <button className={`btn btn-plain prettier widthState33 ${selectedTab === "Inactive" ? "active" : null}`} onClick={(e) => { changeUserSelectedTab(e.target.innerText) }}>Inactive</button>
                                    <button className={`btn btn-plain prettier widthState33 ${selectedTab === "Draft" ? "active" : null}`} onClick={(e) => { changeUserSelectedTab(e.target.innerText) }}>Draft</button>
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>   </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item className={ classes.leftAlignModulePos  } xs={12} ref={container}>
                        {
                            renderBusinessUnit()
                        }
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}

export default BusinessUnit;