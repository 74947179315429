import React, { useEffect, useState  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./style.js";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '../../component/table/index';
import Modal from "../../component/modal";
import { Typography } from "@material-ui/core";
import { getAllPagesContent } from '../../redux/actions/index';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AddNewResouces from './addNewResources';
import { ToastContainer } from "react-toastify";

const Resources = () => {
    const classes = useStyles();
    const dispatch = useDispatch();


    const getPageContent = useSelector((state) => state.getPageContent);

    const oktaAuth = useSelector((state) => state.oktaAuth);
    const { activeRegion , activePage } = useSelector((state) => state.activeDynamicData);

    const [addNew, setAddNew] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const columns = [
        { id: 'id', label: 'S.No.', minWidth: 10, width: 10, align: 'center' },
        { id: 'display_name', label: 'Folder Name', minWidth: 10, width: 300, align: 'left' },
        { id: 'description', label: 'Description', minWidth: 100, width: 'auto', align: 'left' },
    ];

    const { data, loading } = getPageContent;
    useEffect(() => {
        if (!!getPageContent.error) {
            setErrorMessage(getPageContent.userMessage)
        }
    }, [getPageContent]);

    useEffect(() => {
        if (activeRegion && activePage  && activePage.key !== "Playbooks") {
            const params = {
                pageType: activePage.key,
                region: activeRegion,
            }
            dispatch(getAllPagesContent(params));
        }
    }, [dispatch, activePage, activeRegion]);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Grid container item xs={12} spacing={3} className={classes.flexContainer}>
                    <ToastContainer />
                    <Modal
                        open={addNew}
                        bodyElement={
                            <AddNewResouces
                                hideSettingsWindow={(e) => setAddNew(false)}
                                region={activeRegion}
                                pageType={activePage.key}
                            />
                        }
                        title={"Add New Resources"}
                        hideSettingsWindow={(e) => setAddNew(false)}
                        size={"md"}
                    />
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" display="block" align="center" className={classes.title}>
                            <b>{data && data.description && data.description.header}</b>
                        </Typography>
                        {
                            !!oktaAuth && oktaAuth.adminType === "Contributor" ?
                                <div style={{ textAlign: 'end', border: '1px solid $e5e5e5', background: 'e5e5e5' }}>
                                    <button className={`btn ${classes.addNew}`}
                                        onClick={(e) => setAddNew(true)}
                                    ><AddCircleOutlineIcon style={{ width: 15, margin: '0 5px' }} />Add New Resources</button>
                                </div> :
                                null
                        }
                        <div className={classes.folderList}>
                            {
                                !!data && data.description && data.description.table ?
                                    <Table data={data.description.table} loading={loading} type={'Resources'} columns={columns} errorMessage={errorMessage} /> :
                                    <Table data={[]} loading={loading} type={'Resources'} columns={columns} errorMessage={errorMessage} />
                            }
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}

export default Resources;