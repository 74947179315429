export default (state = { country: [] }, action) => {
  switch (action.type) {
    case "FETCH_OPT_ALERTS_TARGET_USER_COUNTRY_REQUEST":
      return {
        country: [],
        loading: true,
        userCountryLoading :   true,
      };

    case "FETCH_OPT_ALERTS_TARGET_USER_COUNTRY_SUCCESS":
      return {
        country: action.payload,
        responseDefault: false,
        loading: false,
        success: true,
        error: false,
        userCountryLoading :   false,
      };

    case "FETCH_OPT_ALERTS_TARGET_USER_COUNTRY_FAILURE":
      return {
        country: [],
        error: true,
        responseDefault: false,
        loading: false,
        success: false,
        userCountryLoading : false,
       };

    default:
      return state;
  }
};
