import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Spinner from '../Spinner/index';
import utils from '../../utils/index';

export default function StickyHeadTable(props) {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const { data, loading, columns, type, errorMessage,selectedTab } = props;

  const { activeRegion,   } = useSelector((state) => state.activeDynamicData);
  const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    container: {
      maxHeight: 425,
      padding: 0, //0 12px
      maxWidth: 'inherit',
    },
    fontSize: {
      fontSize: '12px',
      border: '1px solid #e5e5e5'
    },
  });
  const classes = useStyles();
  useEffect(() => {
    if (!!loading) {
      setPage(0);
    }
  }, [data, loading]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const style = {
    fontSize: '12px',
    textDecoration: 'underline',
    color: '#f8af2a',
    fontStyle: 'italic'
  };

  const trackingEvents = (rowData) => {
    let trackObj = {};
    if (type === "Self Service" ) {
      trackObj.region = activeRegion;
      trackObj.actionType = 'Click';
      trackObj.pageName = type;
      trackObj.reportName = rowData.display_name;
      trackObj.reportLink = rowData.link;
      trackObj.attributeName = selectedTab;
      trackObj.workspaceName = rowData.workspace;
    }
    else{
        trackObj.region = activeRegion;
        trackObj.actionType = 'Click';
        trackObj.pageName = type;
        trackObj.reportLink = rowData.link;
        trackObj.reportName = rowData.display_name;
    }
     utils.userTrackingInfo(trackObj);
  }

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {
                columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, width: column.width }}
                    className={classes.fontSize}
                  >
                    {column.label}
                  </TableCell>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {!!loading ?
              <TableRow hover tabIndex={-1}>
                <TableCell style={{ textAlign: 'center' }}>
                  { }
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                 <Spinner align={'inherit'} />
                </TableCell>
                <TableCell>
                  {}
                </TableCell>
              </TableRow>
              : !!data && Array.isArray(data) && data.length > 0 ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align} className={classes.fontSize}>
                          {
                            column.format && typeof value === 'number' ? column.format(value) :
                              column.id === "display_name" ? <a onClick={() => trackingEvents(row, value, column)} style={style} href={row.link} target="_blank"> {value} </a> :
                                value
                          }
                        </TableCell>
                      );
                    })
                    }
                  </TableRow>
                );
              })
                :
                <TableRow hover role="checkbox" tabIndex={-1}>
                  <TableCell style={{ textAlign: 'center' }}>
                    { }
                  </TableCell>
                  <TableCell style={{ textAlign: 'left' }}>
                    { }
                  </TableCell>
                  <TableCell style={{ textAlign: 'left' }}>
                    {errorMessage}
                  </TableCell>
                </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

