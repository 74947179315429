import {combineReducers} from 'redux';
import apiTesting from './reducers/apiTesting';
import apiFailure from './reducers/apiFailure';
import globalError from './reducers/globalError';
import regions from './reducers/regions';
import globalSearch from './reducers/globalSearch';
import regionValidate from './reducers/regionValidate';
import playbookContent from './reducers/playbookContent';
import playbookContentPages from './reducers/playbookContentPages';
import getEmbedToken from './reducers/getEmbedToken';
import pageContent from "./reducers/pageContent";
import parentBuMapped from "./reducers/parentBuMapped";
import activeSideBarMenu from "./reducers/activeSubMenu";
import sideBarMenu from './reducers/sidebarMenuList';
import adminViews from './reducers/adminViews';
import viewBuilderData from './reducers/viewBuilderJSON';
import selectedView from './reducers/selectedView';
import subReportDetails from './reducers/subReportDetails';
import createdNewDashBoard from './reducers/createdNewDashBoard';
import InsertNewDashBoard from './reducers/InsertNewDashBoard';
import InsertNewSubReport from './reducers/InsertNewSubReport';
import reportContent from './reducers/reportContent';
import updatedSidebarMenuList from './reducers/updatedSideBarMenu';
import updatePowerBIReportPage from './reducers/updatePowerBiReportPage';
import updatedRegion from './reducers/updateBusinessUnitsRegion';
import updatedSubRegion from './reducers/updateBusinessUnitSubRegion';
import oktaAuthStatus from './reducers/updateOktaAuth';
import updateReportSection from './reducers/updateReportSection';
import addNewResources from './reducers/addNewResources';
import reportSection from './reducers/activeReportSection';
import updatePresist from './reducers/updatePresist';
import powerBiReport from './reducers/updatePowerBiReport';
import childParentMapping from './reducers/childParentMapping';
import getDashStatus from './reducers/getDashStatus';
import playbooksRegionFilter from './reducers/playbooksRegionFilter';
import playbooksSelectedRegionFilter from './reducers/playbooksSelectedRegionFilter';
import downloadAppUsageData from './reducers/downloadAppUsageData';
import exportfile from './reducers/exportfile';
import fetchBookmarks from './reducers/fetchBookmark';
import createBookmark from './reducers/createBookmark';
import editBookmarks from './reducers/editBookmark';
import updateBookmark from './reducers/updateBookmark';
import deleteBookmark from './reducers/deleteBookmark';
import bookmarkView from './reducers/updateBookmarkView';
import capturePlaybookData from './reducers/capturePlaybookData';
import captureShareLink from './reducers/captureShareLinkImage';
import fetchShareLinkDetails from './reducers/fetchShareablePlaybooksLink';
import getErrorMessages from './reducers/getErrorMessages';
import updateMessage from './reducers/updateErrorMsg';
import faqLink from './reducers/fetchFaqData';
import apiSuccess from './reducers/apiSuccess';
import activeDynamicData from './reducers/getActiveDynamicData';
import alertType from './reducers/alertType';
import alertField from './reducers/alertField';
import fetchAlerts from './reducers/getOperationalAlerts';
import createAlert from './reducers/createOperationalAlerts';
import updateAlert from './reducers/updateOperationalAlerts';
import optAlertTargetUsers from './reducers/getOptAlertsTargetUser';
import OptAlertsTargetUserCountry from './reducers/fetchOptAlertsTargetUserCountry';
import OptAlertsTargetUserTeam from './reducers/fetchOptAlertsTargetUserTeam';
import optAlertFilter from './reducers/alertFilter';
import appliedAlertFilter from './reducers/appliedAlertFilter';
import publishOperationalAlerts from './reducers/publishOperationalAlerts';
import subscriptionData from './reducers/fetchUserSubscriptionData';
import userSubcription from './reducers/userSubscription';
import notification from './reducers/fetchNotification';
import clearNotifications from './reducers/clearNotification';
import editorReleaseData from './reducers/updateRelNotesEditorData';
import editEditorData from './reducers/editEditorData';
import relNotesFieldData from './reducers/releaseFieldData';
import releaseVersions from './reducers/releaseVersions';
import releaseData from './reducers/releaseDataList';
import newReleaseTitle from './reducers/createNewReleaseTitle';
import createReleaseVersion from './reducers/createReleaseVersion';
import editRelease from './reducers/editReleaseData';
import publishRelease from './reducers/publishReleaseNotes';
import regionalMappingData from './reducers/regionalMappingData';
import notificationRegion from './reducers/notificationRegion';
import createNewsletter from './reducers/newsletter_Create';
import newsletterData from './reducers/newsletter_Data';
import newsletterQuarter from './reducers/newsletter_Quarter';
import updateNewsletter from './reducers/newsletter_Update';
import updateFeatureName from './reducers/updateEditorFeatureName';
import deleteEditorFeature from './reducers/updateEditorFeature';
import updateMenuItemViewState from './reducers/menuItemViewState';
import updatedSideBarActiveMenu from './reducers/activeMenu';
import updateHtmlTemp from './reducers/updateHtmlTemp';
import dynamicReports from './reducers/dynamicReports';
import regionMappingData from './reducers/regionMappingData';
import UpdateReportOrder from './reducers/UpdateReportOrder';
import moduleOperations from './reducers/moduleOperations';

const appReducer = combineReducers({
 apiTesting:apiTesting,
 regions:regions,
 globalSearch: globalSearch,
 regionValidate: regionValidate,
 activeSideBarMenu: activeSideBarMenu,
 activeReportSection: reportSection,
 sideBarMenu: sideBarMenu,
 playbookContent: playbookContent,
 getEmbedToken: getEmbedToken,
 getPageContent: pageContent,
 parentBuMapped: parentBuMapped,
 createdNewDashBoard : createdNewDashBoard,
 InsertNewDashBoard: InsertNewDashBoard,
 InsertNewSubReport: InsertNewSubReport,
 viewBuilderObj: selectedView,
 viewBuilderData: viewBuilderData,
 getAdminViews: adminViews,
 dashBoardStatus : getDashStatus,
 playbookContentPages: playbookContentPages,
 updatedSidebarMenu: updatedSidebarMenuList,
 PowerBIReportPage: updatePowerBIReportPage,
 PowerBIReportPageSection:powerBiReport,
 getReportContent: reportContent,
 getBusinessUnitsRegion: updatedRegion,
 oktaAuth: oktaAuthStatus,
 updatePBIReport : updateReportSection,
 addNewResources : addNewResources,

 updatePresist : updatePresist,
 playbooksSelectedRegionFilter:playbooksSelectedRegionFilter,
 playbooksRegionFilter:playbooksRegionFilter,
 childParentMapping: childParentMapping,
 downloadAppUsageData: downloadAppUsageData,
 updatedSubRegion: updatedSubRegion,
 exportfile: exportfile,
 activeDynamicData:activeDynamicData,
 getBookmarks : fetchBookmarks,
 newBookmark: createBookmark,
 editBookmark : editBookmarks,
 updateBookmark: updateBookmark,
 deleteBookmark: deleteBookmark,
 bookmarkView: bookmarkView,
 capturePlaybookData: capturePlaybookData,
 captureShareLink: captureShareLink,
 fetchShareLinkDetails: fetchShareLinkDetails,
 getErrorMessages: getErrorMessages,
 updateMessage: updateMessage,
 faqLink: faqLink,
 apiFailure: apiFailure,
 globalError: globalError,
 apiSuccess: apiSuccess,
 alertField: alertField,
 alertType: alertType,
 fetchAlerts: fetchAlerts,
 createAlert: createAlert,
 updateAlert: updateAlert,
 optAlertTargetUser: optAlertTargetUsers,
 targetUserCountry: OptAlertsTargetUserCountry,
 targetUserTeam: OptAlertsTargetUserTeam,
 alertFilter : optAlertFilter,
 appliedAlertFilter: appliedAlertFilter,
 publishOperationalAlerts: publishOperationalAlerts,
 subscriptionData: subscriptionData,
 userSubcription: userSubcription,
 notification: notification,
 clearNotifications: clearNotifications,
 editorReleaseData: editorReleaseData,
 editEditorData: editEditorData,
 relNotesFieldData: relNotesFieldData,
 releaseVersions: releaseVersions,
 createReleaseVersion: createReleaseVersion,
 newReleaseTitle:newReleaseTitle,
 editRelease: editRelease,
 publishRelease: publishRelease,
 releaseDataList: releaseData,
 regionalMappingData: regionalMappingData,
 notificationRegion: notificationRegion,
 createNewsletter: createNewsletter,
 newsletterData: newsletterData,
 newsletterQuarter: newsletterQuarter,
 publishNewsletter: updateNewsletter,
 updateFeatureName: updateFeatureName,
 deleteFeature: deleteEditorFeature,
 updateMenuItemViewState: updateMenuItemViewState,
 activePBIReport : updatedSideBarActiveMenu,
 updateCustomizedHtmlTemp: updateHtmlTemp,
 subReportDetails:subReportDetails,
 dynamicReports: dynamicReports,
 getRegionMappingData: regionMappingData,
 UpdateReportOrder: UpdateReportOrder,
 moduleRequest: moduleOperations,
 });

export default appReducer;
